import * as z from 'zod';
import { useState, useRef, type MutableRefObject, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetFooter,
} from '../../common/sheet.tsx';
import { postConfigInvoicingPost } from '@/client/services.gen';
import ConfigGenerationForm from './forms/configForm.js';
import { useToast } from '../../common/use-toast.tsx';
import {
    DateType,
    DeliveryMethod,
    type Invoicing,
} from '@/client/types.gen.ts';
import type { ChargeType } from '@/client/types.gen';
import { getInvoiceChargeTypesGet } from '@/client/services.gen';

interface ConfigDialogProps {
    isDialogOpen: boolean;
    setIsDialogOpen: (open: boolean) => void;
    fetchConfigs: () => Promise<void>;
    initialValues?: Invoicing;
}
const ConfigDialog: React.FC<ConfigDialogProps> = ({
    isDialogOpen,
    setIsDialogOpen,
    fetchConfigs,
    initialValues,
}) => {
    const [loading, setLoading] = useState(false);
    const [invoicecronString, setInvoiceCronString] = useState(
        initialValues?.cadence_invoice_delivery,
    );
    const [chargeInvoiceCronString, setChargeInvoiceCronString] = useState(
        initialValues?.cadence_invoice_level_charge_types,
    );
    const cronValidationRef = useRef(() => {});
    const { toast } = useToast();
    const [chargeTypes, setChargeTypes] = useState<ChargeType[]>([]);

    useEffect(() => {
        const fetchChargeTypes = async () => {
            const { data, error } = await getInvoiceChargeTypesGet();
            if (error) {
                console.error(error);
                return;
            }
            setChargeTypes(data || []);
        };
        fetchChargeTypes();
    }, []);

    const configSchema = z.object({
        same_cadence_as_invoice: z.boolean(),
        package_billing_date_type: z.nativeEnum(DateType),
        delivery_method: z.nativeEnum(DeliveryMethod),
        add_carrier_adjustments: z.boolean(),
        shipping_charge_type_id: z.string().nullable(),
    });

    const formProps = useForm({
        resolver: zodResolver(configSchema),
        defaultValues: {
            ...initialValues,
            invoice_cadence: initialValues?.cadence_invoice_delivery,
            delivery_method: initialValues?.delivery_method || undefined,
            same_cadence_as_invoice: false,
            charge_invoice_cadence:
                initialValues?.cadence_invoice_level_charge_types,
            package_billing_date_type:
                initialValues?.package_billing_date_type || undefined,
            add_carrier_adjustments:
                initialValues?.add_carrier_adjustments || false,
            shipping_charge_type_id:
                initialValues?.shipping_charge_type_id || null,
        },
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values: {
        same_cadence_as_invoice: boolean;
        delivery_method: DeliveryMethod;
        package_billing_date_type: DateType;
        add_carrier_adjustments: boolean;
        shipping_charge_type_id: string | null;
    }) => {
        if (!invoicecronString) {
            toast({
                variant: 'destructive',
                title: 'Missing invoice billing period.',
            });
            return;
        }

        if (!values.same_cadence_as_invoice && !chargeInvoiceCronString) {
            toast({
                variant: 'destructive',
                title: 'Missing invoice level charges billing period.',
            });
            return;
        }

        setLoading(true);

        //@ts-ignore
        const invoicingConfig: Invoicing = {
            package_billing_date_type: values.package_billing_date_type,
            delivery_method: values.delivery_method,
            cadence_invoice_delivery: invoicecronString || '',
            cadence_invoice_level_charge_types: values.same_cadence_as_invoice
                ? invoicecronString || ''
                : chargeInvoiceCronString || '',
            add_carrier_adjustments: values.add_carrier_adjustments,
            shipping_charge_type_id: values.shipping_charge_type_id,
        };

        const runInvoicingConfig = () =>
            postConfigInvoicingPost({ body: invoicingConfig });
        Promise.all([runInvoicingConfig()]).then(async (resp) => {
            if (resp) {
                await fetchConfigs();
                setLoading(false);
                setIsDialogOpen(false);
            }
        });
    };

    return (
        <Sheet open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">
                        Edit Invoicing Configuration
                    </SheetTitle>
                    <ConfigGenerationForm
                        formProps={formProps}
                        setInvoiceCronString={setInvoiceCronString}
                        setChargeInvoiceCronString={setChargeInvoiceCronString}
                        cronValidationRef={
                            cronValidationRef as unknown as MutableRefObject<boolean>
                        }
                        chargeTypes={chargeTypes}
                    />
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <Button
                        className={loading ? 'animate-pulse' : ''}
                        onClick={handleSubmit(onSubmit as any)}
                        type="submit"
                    >
                        {loading ? 'Submitting' : 'Submit'}
                    </Button>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default ConfigDialog;
