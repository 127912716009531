import {
    createV2WmsInvoiceCustomersPost,
    type InvoiceCustomer,
} from '@/client';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useState } from 'react';

interface CreateCustomerProps {
    createDialogOpen: boolean;
    setCreateDialogOpen: (open: boolean) => void;
    onCustomerCreated: () => void;
}

const CreateCustomer = ({
    createDialogOpen,
    setCreateDialogOpen,
    onCustomerCreated,
}: CreateCustomerProps) => {
    const [formData, setFormData] = useState<InvoiceCustomer>({
        company_id: '',
        name: '',
        email: null,
        active: true,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCreateSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await createV2WmsInvoiceCustomersPost({
                body: formData,
                throwOnError: true,
            });
            setCreateDialogOpen(false);
            onCustomerCreated();
            // Reset form data
            setFormData({
                company_id: '',
                name: '',
                email: null,
                active: true,
            });
        } catch (error) {
            console.error('Error creating customer:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    return (
        <Dialog open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
            <DialogTrigger asChild>
                <Button variant="default">Create Customer</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create New Customer</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleCreateSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Creating...' : 'Create'}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateCustomer;
