import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { getInvoiceSummaryInvoiceInvoiceSummariesGet, statsInvoiceStatsGet } from '@/client/services.gen';
import { type StatsInvoiceStatsGetResponse, Aggregation, Period, InvoiceColSelect, InvoiceColAggBy } from '@/client/types.gen';
import { subWeeks } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { BarChartCard } from '@/components/ui/charts/bar';

interface Invoice {
    customer_name: string;
    invoice_id: string;
    billing_period_start: string;
    billing_period_end: string;
    total_invoice_amount: number;
    status: string;
    numberOfInvoices: number;
    invoiceAmountLast7Days: number;
    invoiceAmountLast30Days: number;
    invoiceAmountThisQuarter: number;
    invoiceAmountThisYear: number;
}

interface InvoiceStats {
    weeklyInvoiceCount: number;
    weeklyInvoiceCountChange: number;
    totalInvoicedAmount: number;
    totalInvoicedAmountChange: number;
    openInvoicesCount: number;
    openInvoicesTotalAmount: number;
}

const invoiceColumns: any[] = [
    {
        accessorKey: 'customer_name',
        header: 'Customer',
        cell: ({ row }: { row: any }) => (
            <span className="w-[200px] font-medium hover:underline">
                <Button variant="link" className="text-black">
                    {row.getValue('customer_name')}
                </Button>
            </span>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'numberOfInvoices',
        header: 'Number of Invoices',
        cell: ({ row }: { row: any }) => <span className="max-w-[500px] text-wrap">{row.getValue('numberOfInvoices')}</span>,
        enableSorting: true,
    },
    {
        accessorKey: 'totalInvoiceAmount',
        header: 'Total Invoice Amount',
        cell: ({ row }: { row: any }) => (
            <NumericFormat value={row.getValue('totalInvoiceAmount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'invoiceAmountLast7Days',
        header: 'Invoice Amount (Last 7 Days)',
        cell: ({ row }: { row: any }) => (
            <NumericFormat value={row.getValue('invoiceAmountLast7Days')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'invoiceAmountLast30Days',
        header: 'Invoice Amount (Last 30 Days)',
        cell: ({ row }: { row: any }) => (
            <NumericFormat value={row.getValue('invoiceAmountLast30Days')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'invoiceAmountThisQuarter',
        header: 'Invoice Amount (This Quarter)',
        cell: ({ row }: { row: any }) => (
            <NumericFormat value={row.getValue('invoiceAmountThisQuarter')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'invoiceAmountThisYear',
        header: 'Invoice Amount (This Year)',
        cell: ({ row }: { row: any }) => (
            <NumericFormat value={row.getValue('invoiceAmountThisYear')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
        ),
        enableSorting: true,
    },
];

export default function InvoiceInsights() {
    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    const [invoiceTableData, setInvoiceTableData] = useState<Invoice[]>([]);
    const [invoiceStats, setInvoiceStats] = useState<InvoiceStats>({
        weeklyInvoiceCount: 0,
        weeklyInvoiceCountChange: 0,
        totalInvoicedAmount: 0,
        totalInvoicedAmountChange: 0,
        openInvoicesCount: 0,
        openInvoicesTotalAmount: 0,
    });

    const [error, setError] = useState<string | null>(null);
    const [chartStats, setChartStats] = useState<StatsInvoiceStatsGetResponse>();

    const handleFetchChartStats = async (params: any) => {
        setChartLoading(true);
        setError(null);
        try {
            const response = await statsInvoiceStatsGet({ query: params });
            setChartStats(response.data);
        } catch (error) {
            console.error('Error fetching chart stats:', error);
            setError('Error fetching chart stats');
        } finally {
            setChartLoading(false);
        }
    };

    const fetchInvoices = async () => {
        try {
            const response = await getInvoiceSummaryInvoiceInvoiceSummariesGet();
            const now = new Date();
            const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
            const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
            const startOfYear = new Date(now.getFullYear(), 0, 1);

            const groupedData = response.data!.reduce((acc: { [key: string]: any }, invoice) => {
                const customer = invoice.customer_name;
                if (!acc[customer]) {
                    acc[customer] = {
                        customer_name: customer,
                        numberOfInvoices: 0,
                        totalInvoiceAmount: 0,
                        invoiceAmountLast7Days: 0,
                        invoiceAmountLast30Days: 0,
                        invoiceAmountThisQuarter: 0,
                        invoiceAmountThisYear: 0,
                    };
                }

                const invoiceDate = new Date(invoice.billing_period_end);
                const invoiceAmount = parseFloat(invoice.total_invoice_amount.toString());

                acc[customer].numberOfInvoices++;
                acc[customer].totalInvoiceAmount += invoiceAmount;

                if (invoiceDate >= oneWeekAgo) {
                    acc[customer].invoiceAmountLast7Days += invoiceAmount;
                }
                if (invoiceDate >= thirtyDaysAgo) {
                    acc[customer].invoiceAmountLast30Days += invoiceAmount;
                }
                if (invoiceDate >= startOfQuarter) {
                    acc[customer].invoiceAmountThisQuarter += invoiceAmount;
                }
                if (invoiceDate >= startOfYear) {
                    acc[customer].invoiceAmountThisYear += invoiceAmount;
                }

                return acc;
            }, {});

            const formattedData = Object.values(groupedData);

            setInvoiceTableData(formattedData as Invoice[]);

            // Calculate invoice stats
            const thisWeekInvoices = response.data!.filter((invoice) => new Date(invoice.billing_period_end) >= oneWeekAgo);
            const lastWeekInvoices = response.data!.filter((invoice) => {
                const invoiceDate = new Date(invoice.billing_period_end);
                return invoiceDate >= twoWeeksAgo && invoiceDate < oneWeekAgo;
            });

            const weeklyInvoiceCount = thisWeekInvoices.length;
            const lastWeekInvoiceCount = lastWeekInvoices.length;
            const weeklyInvoiceCountChange =
                lastWeekInvoiceCount !== 0 ? ((weeklyInvoiceCount - lastWeekInvoiceCount) / lastWeekInvoiceCount) * 100 : weeklyInvoiceCount > 0 ? 100 : 0;

            const totalInvoicedAmount = thisWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);
            const lastWeekTotalInvoicedAmount = lastWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);
            const totalInvoicedAmountChange =
                lastWeekTotalInvoicedAmount !== 0 ? ((totalInvoicedAmount - lastWeekTotalInvoicedAmount) / lastWeekTotalInvoicedAmount) * 100 : totalInvoicedAmount > 0 ? 100 : 0;

            const openInvoices = response.data!.filter((invoice) => invoice.status === 'open');
            const openInvoicesCount = openInvoices.length;
            const openInvoicesTotalAmount = openInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);

            setInvoiceStats({
                weeklyInvoiceCount,
                weeklyInvoiceCountChange: isNaN(weeklyInvoiceCountChange) ? 0 : weeklyInvoiceCountChange,
                totalInvoicedAmount,
                totalInvoicedAmountChange: isNaN(totalInvoicedAmountChange) ? 0 : totalInvoicedAmountChange,
                openInvoicesCount,
                openInvoicesTotalAmount,
            });
        } catch (error) {
            console.error('Error fetching invoice data:', error);
            setError('Error fetching invoice data');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await handleFetchChartStats({
                col_agg_by: 'CUSTOMER_NAME',
                col_select: 'CHARGE_AMOUNT',
                type_agg: 'sum',
                type_date: 'week',
                start_date: subWeeks(new Date(), 6).toISOString(),
                end_date: new Date().toISOString(),
            });
            await fetchInvoices();
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <p className="text-xl">Loading...</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-8">
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <p className="text-xl">Loading...</p>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-4 gap-4 mb-8">
                        <Card>
                            <CardHeader>
                                <CardTitle>Invoices Generated</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-3xl font-bold">{invoiceStats.weeklyInvoiceCount}</p>
                                <p className={`text-sm ${invoiceStats.weeklyInvoiceCountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {invoiceStats.weeklyInvoiceCountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.weeklyInvoiceCountChange).toFixed(2)}% from last week
                                </p>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Total Invoiced Amount</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-3xl font-bold">
                                    <NumericFormat
                                        value={invoiceStats.totalInvoicedAmount}
                                        displayType="text"
                                        thousandSeparator=","
                                        prefix="$"
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </p>
                                <p className={`text-sm ${invoiceStats.totalInvoicedAmountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {invoiceStats.totalInvoicedAmountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.totalInvoicedAmountChange).toFixed(2)}% from last week
                                </p>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Open Invoices</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-3xl font-bold">{invoiceStats.openInvoicesCount}</p>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Outstanding Amount</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-3xl font-bold">
                                    <NumericFormat
                                        value={invoiceStats.openInvoicesTotalAmount}
                                        displayType="text"
                                        thousandSeparator=","
                                        prefix="$"
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </p>
                            </CardContent>
                        </Card>
                    </div>

                    <BarChartCard
                        title="Revenue Insights"
                        description="Analyze customer and charge level revenue"
                        data={chartStats || []}
                        dataKeys={[]}
                        xAxisDataKey="date"
                        onFetchData={handleFetchChartStats}
                        isLoading={chartLoading}
                        error={error}
                        aggregateOptions={Object.fromEntries(Object.entries(InvoiceColAggBy).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                        columnOptions={Object.fromEntries(Object.entries(InvoiceColSelect).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                        aggregationTypeOptions={Object.fromEntries(Object.entries(Aggregation).map(([key, value]) => [value, key]))}
                        dateTypeOptions={Object.fromEntries(Object.entries(Period).map(([key, value]) => [value, key]))}
                    />

                    <div className="mt-8">
                        {invoiceTableData && (
                            <DataTable
                                loading={isLoading}
                                data={invoiceTableData}
                                columns={invoiceColumns}
                                isMultiSelectRows={false}
                                showActionButtonInToolbar
                                isDownloadable={true}
                                downloadData={invoiceTableData}
                                tableName="invoices"
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
