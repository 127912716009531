import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import ServiceLevelNotification from './serviceLevelNotification';

export default function Notifications() {
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUsername(user.displayName || user.email || '');
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <p className="text-xl">Loading...</p>
            </div>
        );
    }

    return (
        <div className="h-screen flex overflow-hidden bg-white" data-testid="notifications">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <h1 className="text-xl font-bold mb-4 ml-8">Hello, {username}!</h1>
                        <ServiceLevelNotification /> 
                    </div>
                </main>
            </div>
        </div>
    );
}
