import { useEffect, useState } from 'react';
import {
    updateV2WmsInvoiceCustomersIdPut,
    setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost,
} from '@/client/services.gen';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import * as dialog from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
    type InvoiceCustomerPublic,
    type WMSCustomer,
} from '@/client/types.gen';
import {
    Form,
    FormField,
    FormItem,
    FormControl,
    FormMessage,
    FormLabel,
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Select from 'react-select';

export interface EditCustomerProps {
    invoiceCustomer: InvoiceCustomerPublic | null;
    wmsCustomers: WMSCustomer[];
    isEditDialogOpen: boolean;
    setIsEditDialogOpen: (open: boolean) => void;
    onEditSuccess: () => void;
}

const EditCustomer = ({
    invoiceCustomer,
    wmsCustomers,
    isEditDialogOpen,
    setIsEditDialogOpen,
    onEditSuccess,
}: EditCustomerProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const customerSchema = z.object({
        name: z.string().min(1, 'Name is required'),
        active: z.boolean(),
        wmsCustomerIds: z.array(z.string()).nullable(),
    });
    const form = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        defaultValues: {
            name: invoiceCustomer?.name ?? '',
            active: invoiceCustomer?.active ?? true,
            wmsCustomerIds: invoiceCustomer?.wms_customers.map((c) => c.id),
        },
    });

    if (!invoiceCustomer) {
        return null;
    }

    const onSubmit = async (values: z.infer<typeof customerSchema>) => {
        try {
            setIsSubmitting(true);
            await updateV2WmsInvoiceCustomersIdPut({
                path: { id: invoiceCustomer.id! },
                body: {
                    name: values.name,
                    active: values.active,
                    company_id: invoiceCustomer.company_id,
                    email: invoiceCustomer.email,
                },
            });

            if (values.wmsCustomerIds?.length) {
                await Promise.all(
                    values.wmsCustomerIds.map((id) =>
                        setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost(
                            {
                                path: { id },
                                query: {
                                    invoice_customer_id: invoiceCustomer.id!,
                                },
                            },
                        ),
                    ),
                );
            }
            onEditSuccess();
            setIsEditDialogOpen(false);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (invoiceCustomer) {
            form.reset({
                name: invoiceCustomer.name ?? '',
                active: invoiceCustomer.active ?? true,
                wmsCustomerIds: invoiceCustomer.wms_customers.map((c) => c.id),
            });
        }
    }, [invoiceCustomer, form]);

    return (
        <dialog.Dialog
            open={isEditDialogOpen}
            onOpenChange={setIsEditDialogOpen}
        >
            <dialog.DialogContent>
                <dialog.DialogHeader>
                    <dialog.DialogTitle>Edit Customer</dialog.DialogTitle>
                </dialog.DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    >
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="active"
                            render={({ field }) => (
                                <FormItem>
                                    <div className="flex items-center space-x-2">
                                        <FormLabel>Active</FormLabel>
                                        <FormControl>
                                            <Switch
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="wmsCustomerIds"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>WMS Customers</FormLabel>
                                    <FormControl>
                                        <Select
                                            isMulti
                                            value={wmsCustomers
                                                .filter((c) =>
                                                    field.value?.includes(
                                                        c.id!,
                                                    ),
                                                )
                                                .map((c) => ({
                                                    value: c.id,
                                                    label: c.name,
                                                }))}
                                            onChange={(options) =>
                                                field.onChange(
                                                    options?.map(
                                                        (opt) => opt.value,
                                                    ) ?? [],
                                                )
                                            }
                                            options={wmsCustomers.map((c) => ({
                                                value: c.id,
                                                label: c.name,
                                            }))}
                                            className="w-full"
                                            classNamePrefix="select"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Updating...' : 'Update Customer'}
                        </Button>
                    </form>
                </Form>
            </dialog.DialogContent>
        </dialog.Dialog>
    );
};

export default EditCustomer;
