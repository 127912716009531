import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ProductSearchForm from './forms/productSearchForm';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/common/card';

const ProductSearch = ({ open, setOpen, isLoading, setIsLoading, onSearchQuery, resetTable, customers, profiles }) => {
    const productSearchSchema = z
        .object({
            skus: z.string().optional(),
            customers: z.array(z.string()).optional(),
            profile_id: z.array(z.string()).optional(),
        })
        .refine((input) => Object.values(input).some((value) => value !== null && value !== ''), {
            message: 'Please select at least one field to search ',
        });

    const defaultFormValues = {
        skus: '',
        customers: [],
        profile_id: [],
    };

    const searchFormProps = useForm({
        resolver: zodResolver(productSearchSchema),
        defaultValues: defaultFormValues,
    });

    const { handleSubmit } = searchFormProps;

    const onSubmit = (values) => {
        setOpen(false);
        const searchQuery = Object.entries(values).reduce(
            (acc, [key, value]) => {
                if (value != null && value !== '') {
                    acc[key] = value;
                }
                return acc;
            },
            { limit: 1000 },
        );
        onSearchQuery(searchQuery);
    };

    return (
        <Card>
            <CardHeader className="grid grid-cols-6">
                <CardTitle className="col-span-5 text-3xl">Search Products</CardTitle>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </CardHeader>
            <CardContent>
                <ProductSearchForm formProps={searchFormProps} _isLoading={isLoading} _setIsLoading={setIsLoading} customers={customers} profiles={profiles} />
            </CardContent>
            <CardFooter>
                <div className="grid grid-cols-2">
                    <div className="text-left">
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            Submit Search
                        </Button>
                    </div>
                    <div className="text-right">
                        <Button onClick={() => resetTable()}>Reset Table</Button>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
};

export default ProductSearch;
