import { remoteConfig } from '@/firebase';
import { fetchAndActivate, getValue } from 'firebase/remote-config';

remoteConfig.settings.minimumFetchIntervalMillis = 36000;
// the in-app config can be overriden in the firebase console
remoteConfig.defaultConfig = {
    adHocInvoiceCreate: 'false',
};

export const evaluateFeatureFlag = async (key: string): Promise<boolean> => {
    let featureFlag: boolean;
    try {
        await fetchAndActivate(remoteConfig);
        featureFlag = getValue(remoteConfig, key).asBoolean();
    } catch (e) {
        console.error(e);
        return remoteConfig.defaultConfig[key] === 'true';
    }
    return featureFlag;
};
