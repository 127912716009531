import { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table.tsx';
import {
    readManyV2WmsInvoiceCustomersGet,
    readManyV2WmsCustomersGet,
} from '@/client/services.gen';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import {
    type InvoiceCustomerPublic,
    type WMSCustomer,
} from '@/client/types.gen';
import { PencilIcon } from '@heroicons/react/24/outline';
import type { Row } from '@tanstack/react-table';
import EditCustomer from './editCustomer';
import CreateCustomer from './createCustomer';
import { useAuth } from '../auth/AuthProvider.tsx';
import CustomerPortalAdmin from './customerPortalAdmin.tsx';

const Customers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<InvoiceCustomerPublic[]>([]);
    const [wmsCustomers, setWmsCustomers] = useState<WMSCustomer[]>([]);
    const [selectedCustomer, setSelectedCustomer] =
        useState<InvoiceCustomerPublic | null>(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [hasBrandPortalEnabled, setHasBrandPortalEnabled] = useState(false);

    const { userClaims, userHasClaim } = useAuth();

    useEffect(() => {
        setHasBrandPortalEnabled(userHasClaim('brandAdmin'));
    }, [userClaims]);

    const fetchInvoiceCustomers = async () => {
        setIsLoading(true);
        try {
            const response = await readManyV2WmsInvoiceCustomersGet({
                throwOnError: true,
            });
            setTableData(response.data);
        } catch (error) {
            throw new Error('Error fetching customers');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchWmsCustomers = async () => {
        setIsLoading(false);
        try {
            const response = await readManyV2WmsCustomersGet({
                throwOnError: true,
            });
            setWmsCustomers(response.data);
        } catch (error) {
            throw new Error('Error fetching WMS customers');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchInvoiceCustomers(), fetchWmsCustomers()]);
        };
        fetchData();
    }, []);

    const handleCustomerCreated = () => {
        fetchInvoiceCustomers(); // Refresh table data
    };

    const handleEditDialogChange = (open: boolean) => {
        setIsEditDialogOpen(open);
        if (!open) {
            setSelectedCustomer(null);
        }
    };

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            filterType: 'text',
            enableColumnFilter: true,
            cell: ({ row }: { row: Row<InvoiceCustomerPublic> }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px]">{row.getValue('name')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'active',
            header: 'Active',
            filterType: 'boolean',
            enableColumnFilter: true,
            cell: ({ row }: { row: Row<InvoiceCustomerPublic> }) => (
                <div className="w-[200px]">
                    <Switch checked={row.getValue('active')} disabled />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'wms_customers',
            header: 'WMS Customers',
            cell: ({ row }: { row: Row<InvoiceCustomerPublic> }) => {
                const customers = row.getValue(
                    'wms_customers',
                ) as WMSCustomer[];
                const names = customers.reduce(
                    (acc: string, curr: WMSCustomer, idx: number) =>
                        idx === 0 ? curr.name : acc + ', ' + curr.name,
                    '',
                );

                return <div className="w-[200px]">{names}</div>;
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            cell: ({ row }: { row: Row<InvoiceCustomerPublic> }) => (
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                        setSelectedCustomer(row.original);
                        setIsEditDialogOpen(true);
                    }}
                >
                    <PencilIcon
                        className="h-4 w-4"
                        data-testid="edit-customer"
                    />
                </Button>
            ),
        },
    ];
    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">
                                Customers
                            </h1>
                            <h2 className="text-xs text-rails-dark-blue">
                                Manage customers for invoicing.
                            </h2>
                            <div className="container mx-auto py-8">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        collapsible={hasBrandPortalEnabled}
                                        collapsibleContent={({ row }) => (
                                            <CustomerPortalAdmin
                                                invoiceCustomer={
                                                    row as InvoiceCustomerPublic
                                                }
                                            />
                                        )}
                                        columns={columns}
                                        ActionButton={
                                            <CreateCustomer
                                                createDialogOpen={
                                                    createDialogOpen
                                                }
                                                setCreateDialogOpen={
                                                    setCreateDialogOpen
                                                }
                                                onCustomerCreated={
                                                    handleCustomerCreated
                                                }
                                            />
                                        }
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="customers"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {selectedCustomer && (
                <EditCustomer
                    invoiceCustomer={selectedCustomer}
                    wmsCustomers={wmsCustomers}
                    isEditDialogOpen={isEditDialogOpen}
                    setIsEditDialogOpen={handleEditDialogChange}
                    onEditSuccess={() => fetchInvoiceCustomers()}
                />
            )}
        </div>
    );
};

export default Customers;
