import React, { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Plus, XCircleIcon, CircleIcon } from 'lucide-react';
import {
    getQbAccountsGet,
    getQbDataQbQbUiFormOptionsGet,
    quickbooksAuthUrlQbQuickbooksAuthUrlGet,
    createConnectionQbCreateConnectionPost,
    getInvoiceChargeTypesGet,
    readManyV2WmsInvoiceCustomersGet,
    readManyQbPaymentTermsGet,
    readManyQbInvoiceFormatGet,
    readManyQbChargesGet,
    readManyQbCustomersGet,
    deleteInBulkQbCustomersBulkDelete,
    createInBulkQbCustomersBulkPost,
    deleteInBulkQbPaymentTermsBulkDelete,
    createInBulkQbPaymentTermsBulkPost,
    deleteInBulkQbInvoiceFormatBulkDelete,
    createInBulkQbInvoiceFormatBulkPost,
    deleteInBulkQbChargesBulkDelete,
    createInBulkQbChargesBulkPost,
    updateAccountQbAccountsAccountIdPut,
} from '@/client/services.gen';

import {
    type InvoiceCustomer,
    type Customers,
    type PaymentTerms_Output,
    type InvoiceFormat,
    type QBAccount,
    type GetQbDataQbQbUiFormOptionsGetResponse,
    type GetInvoiceChargeTypesGetResponse,
    type Charges,
    type ReadManyQbChargesGetResponse,
} from '@/client/types.gen';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import Select from 'react-select';

// Helper functions for service category transformations
const transformServiceCategoryForForm = (category: ReadManyQbChargesGetResponse[number], chargeTypes: GetInvoiceChargeTypesGetResponse) => {
    const chargeType = chargeTypes.find((c) => c.id === category.charge_type_id);
    return {
        name: chargeType?.charge_on_invoice || '',
        quickbooks_name: category.quickbooks_name || '',
        charge_type_id: category.charge_type_id || '',
        quickbooks_charge_id: category.quickbooks_charge_id || '',
    };
};

const isConnectionActive = (lastRefreshed: string | null): boolean => {
    if (!lastRefreshed) return false;
    const lastRefreshDate = new Date(lastRefreshed);
    const now = new Date();
    const diffHours = (now.getTime() - lastRefreshDate.getTime()) / (1000 * 60 * 60);
    return diffHours <= 24;
};

const AccountingSystems: React.FC = () => {
    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms_Output[]>([]);
    const [reportFormats, setReportFormats] = useState<InvoiceFormat[]>([]);
    const [customerMappings, setCustomerMappings] = useState<Customers[]>([]);
    const [serviceCategories, setServiceCategories] = useState<Charges[]>([]);
    const [qbAccounts, setQbAccounts] = useState<QBAccount[]>([]);
    const [qbData, setQbData] = useState<GetQbDataQbQbUiFormOptionsGetResponse>({} as GetQbDataQbQbUiFormOptionsGetResponse);
    const [chargeTypes, setChargeTypes] = useState<GetInvoiceChargeTypesGetResponse>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [isPaymentTermDialogOpen, setIsPaymentTermDialogOpen] = useState(false);
    const [isReportFormatDialogOpen, setIsReportFormatDialogOpen] = useState(false);
    const [isAccountingSystemDialogOpen, setIsAccountingSystemDialogOpen] = useState(false);
    const [isQuickBooksSuccessDialogOpen, setIsQuickBooksSuccessDialogOpen] = useState(false);
    const [isCustomerMappingDialogOpen, setIsCustomerMappingDialogOpen] = useState(false);
    const [isServiceCategoryDialogOpen, setIsServiceCategoryDialogOpen] = useState(false);
    const [customers, setCustomers] = useState<InvoiceCustomer[]>([]);

    const getCustomers = async () => {
        const { data, error } = await readManyV2WmsInvoiceCustomersGet<false>();
        if (error) {
            console.error('Error loading customers:', error);
            return;
        }
        setCustomers(data!);
    };

    const getQbAccounts = async () => {
        const { data, error } = await getQbAccountsGet<false>();
        if (error) {
            console.error('Error loading QuickBooks Accounts data:', error);
            return;
        }
        setQbAccounts(data!);
    };

    const getPaymentTerms = async () => {
        const { data, error } = await readManyQbPaymentTermsGet<false>();
        if (error) {
            console.error('Error loading Payment Terms data:', error);
            return;
        }
        setPaymentTerms(data!);
    };

    const getReportFormats = async () => {
        const { data, error } = await readManyQbInvoiceFormatGet<false>();
        if (error) {
            console.error('Error loading Report Formats data:', error);
            return;
        }
        setReportFormats(data!);
    };

    const getCustomerMappings = async () => {
        const { data, error } = await readManyQbCustomersGet<false>();
        if (error) {
            console.error('Error loading Customer Mappings data:', error);
            return;
        }
        setCustomerMappings(data!);
    };

    const getServiceCategories = async () => {
        const { data, error } = await readManyQbChargesGet<false>();
        if (error) {
            console.error('Error loading Service Categories data:', error);
            return;
        }
        setServiceCategories(data!);
    };

    const getQbData = async () => {
        const { data, error } = await getQbDataQbQbUiFormOptionsGet<false>();
        if (error) {
            console.error('Error loading QuickBooks Data:', error);
            return;
        }
        setQbData(data!);
    };

    const getChargeTypes = async () => {
        const { data, error } = await getInvoiceChargeTypesGet<false>();
        if (error) {
            console.error('Error loading Charge Types data:', error);
            return;
        }
        setChargeTypes(data!);
    };

    const continueExistingQbSetup = async (params: URLSearchParams) => {
        setLoading(true);
        try {
            // Check if there's an existing QB account
            const { data: existingAccounts } = await getQbAccountsGet<false>();

            if (existingAccounts && existingAccounts.length > 0) {
                // Update existing account
                const { error } = await updateAccountQbAccountsAccountIdPut<false>({
                    path: {
                        accountId: existingAccounts[0].id,
                    },
                    body: {
                        auth_code: params.get('code') ?? '',
                        realm_id: params.get('realmId') ?? '',
                    },
                });

                if (error) {
                    console.error('Error updating QuickBooks connection:', error);
                    return;
                }
            } else {
                // Create new connection
                const { error } = await createConnectionQbCreateConnectionPost<false>({
                    body: {
                        auth_code: params.get('code') ?? '',
                        realm_id: params.get('realmId') ?? '',
                    },
                });

                if (error) {
                    console.error('Error connecting to QuickBooks:', error);
                    return;
                }
            }

            await Promise.all([getQbAccounts(), loadInitialData()]);
        } catch (error) {
            console.error('Error in QuickBooks setup:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadInitialData = async () => {
        setLoading(true);
        try {
            // Load QB accounts first
            const { data: qbAccountsData } = await getQbAccountsGet<false>();
            setQbAccounts(qbAccountsData!);

            // Load all data that doesn't require QB account
            const basicDataPromises = [getCustomers(), getPaymentTerms(), getReportFormats(), getCustomerMappings(), getServiceCategories(), getChargeTypes()];

            // Only get QB data if we have an account
            if (qbAccountsData && qbAccountsData.length > 0) {
                basicDataPromises.push(getQbData());
            }

            await Promise.all(basicDataPromises);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('code') && params.get('realmId')) {
            continueExistingQbSetup(params);
        } else {
            loadInitialData();
        }
    }, []);

    const groupPaymentTerms = () => {
        return paymentTerms.reduce(
            (acc, paymentTerm) => {
                const key = `${paymentTerm.allow_ach}-${paymentTerm.allow_credit_card}-${paymentTerm.credit_card_fee}-${paymentTerm.quickbooks_invoice_net_terms_name}`;
                const customer = customers.find((c) => c.id === paymentTerm.invoice_customer_id);

                // Skip if customer not found
                if (!customer) return acc;

                if (!acc[key]) {
                    acc[key] = {
                        allow_ach: paymentTerm.allow_ach ?? false,
                        allow_credit_card: paymentTerm.allow_credit_card ?? false,
                        credit_card_fee: paymentTerm.credit_card_fee ?? '0',
                        quickbooks_invoice_net_terms_name: paymentTerm.quickbooks_invoice_net_terms_name,
                        customers: [customer.name],
                    };
                } else {
                    acc[key].customers.push(customer.name);
                }
                return acc;
            },
            {} as Record<string, { allow_ach: boolean; allow_credit_card: boolean; credit_card_fee: string; quickbooks_invoice_net_terms_name: string; customers: string[] }>,
        );
    };

    const groupReportFormats = () => {
        const reportFormatGroupedData = Object.values(
            reportFormats.reduce(
                (acc, reportFormat) => {
                    const key = `${reportFormat.report_zero_charge}-${reportFormat.email_to_cc_bill}-${reportFormat.customer_memo}`;
                    const customer = customers.find((c) => c.id === reportFormat.invoice_customer_id);

                    // Skip if customer not found
                    if (!customer) return acc;

                    const customerName = customer.name;

                    if (!acc[key]) {
                        acc[key] = {
                            //@ts-ignore
                            id: reportFormat.id,
                            report_zero_charge: reportFormat.report_zero_charge || false,
                            email_to_cc_bill: reportFormat.email_to_cc_bill || '',
                            customer_memo: reportFormat.customer_memo || '',
                            customers: [customerName],
                            customer_ids: [reportFormat.invoice_customer_id],
                        };
                    } else {
                        if (!acc[key].customer_ids.includes(reportFormat.invoice_customer_id)) {
                            acc[key].customers.push(customerName);
                            acc[key].customer_ids.push(reportFormat.invoice_customer_id);
                        }
                    }
                    return acc;
                },
                {} as Record<
                    string,
                    {
                        id: string;
                        report_zero_charge: boolean;
                        email_to_cc_bill: string;
                        customer_memo: string;
                        customers: string[];
                        customer_ids: string[];
                    }
                >,
            ),
        );

        return reportFormatGroupedData;
    };

    const paymentTermColumns = [
        {
            header: 'Customers',
            accessorKey: 'customers',
            cell: ({ row }: { row: { original: { customers: string[] } } }) => (
                <div className="w-[200px]" title={row.original.customers.join(', ')}>
                    {row.original.customers.join(', ')}
                </div>
            ),
        },
        {
            header: 'Allow ACH',
            accessorKey: 'allow_ach',
        },
        {
            header: 'Allow Credit Card',
            accessorKey: 'allow_credit_card',
        },
        {
            header: 'Custom Credit Card Processing Fee',
            accessorKey: 'credit_card_fee',
            cell: ({ row }: { row: { original: { credit_card_fee: string } } }) => (
                <div>{row.original.credit_card_fee === '0E-10' ? '0%' : `${(Math.ceil(parseFloat(row.original.credit_card_fee) * 1000) / 1000).toFixed(3)}%`}</div>
            ),
        },
        {
            header: 'Net Terms',
            accessorKey: 'quickbooks_invoice_net_terms_name',
            cell: ({ row }: { row: { original: { quickbooks_invoice_net_terms_name: string } } }) => <div>{row.original.quickbooks_invoice_net_terms_name}</div>,
        },
    ];

    const reportFormatColumns = [
        {
            header: 'Customers',
            accessorKey: 'customers',
            cell: ({ row }: { row: { original: { customers: string[] } } }) => (
                <div className="w-[200px]" title={row.original.customers.join(', ')}>
                    {row.original.customers.join(', ')}
                </div>
            ),
        },
        {
            header: 'Report Zero Charges',
            accessorKey: 'report_zero_charge',
        },
        {
            header: 'Email To CC Bill',
            accessorKey: 'email_to_cc_bill',
            cell: ({ row }: { row: { original: { email_to_cc_bill: string | null } } }) => <div>{row.original.email_to_cc_bill || '-'}</div>,
        },
        {
            header: 'Customer Memo',
            accessorKey: 'customer_memo',
            cell: ({ row }: { row: { original: { customer_memo: string | null } } }) => <div>{row.original.customer_memo || '-'}</div>,
        },
    ];

    const customerMappingColumns = [
        {
            header: 'Rails Profile',
            accessorKey: 'invoice_customer_id',
            cell: ({ row }: { row: { original: { invoice_customer_id: string } } }) => {
                const customer = customers.find((c) => c.id === row.original.invoice_customer_id);
                return <div>{customer ? customer.name : 'Unknown'}</div>;
            },
        },
        {
            header: 'QuickBooks Customer',
            accessorKey: 'quickbooks_name',
        },
    ];

    const serviceCategoryColumns = [
        {
            header: 'Rails Charge',
            accessorKey: 'charge_type_id',
            cell: ({ row }: { row: { original: { charge_type_id: string } } }) => {
                const chargeType = chargeTypes.find((c) => c.id === row.original.charge_type_id);
                return <div>{chargeType?.charge_on_invoice || '-'}</div>;
            },
        },
        {
            header: 'QuickBooks Service',
            accessorKey: 'quickbooks_name',
            cell: ({ row }: { row: { original: { quickbooks_name: string } } }) => <div>{row.original.quickbooks_name || '-'}</div>,
        },
    ];

    const paymentTermGroupedData = Object.values(groupPaymentTerms());
    const reportFormatGroupedData = Object.values(groupReportFormats());

    const paymentTermFormSchema = z.object({
        allow_ach: z.boolean(),
        allow_credit_card: z.boolean(),
        credit_card_fee: z.string().regex(/^\d*\.?\d*$/, 'Must be a valid number'),
        customer_ids: z.array(z.string()).nonempty('At least one customer must be selected'),
        quickbooks_invoice_net_terms: z
            .object({
                value: z.string(),
                label: z.string(),
            })
            .required(),
    });

    const paymentTermForm = useForm<z.infer<typeof paymentTermFormSchema>>({
        resolver: zodResolver(paymentTermFormSchema),
        defaultValues: {
            allow_ach: false,
            allow_credit_card: false,
            credit_card_fee: '0',
            customer_ids: [],
            quickbooks_invoice_net_terms: {
                value: '',
                label: '',
            },
        },
    });

    const onSubmitPaymentTerm = async (values: z.infer<typeof paymentTermFormSchema>) => {
        try {
            // Find existing payment terms for the selected customers
            const existingTermsToDelete = paymentTerms.filter((term) => values.customer_ids.includes(term.invoice_customer_id)).map((term) => term.id!);

            // Only delete terms for the customers being updated
            if (existingTermsToDelete.length > 0) {
                await deleteInBulkQbPaymentTermsBulkDelete<false>({
                    body: existingTermsToDelete,
                });
            }

            // Create new terms for the selected customers
            await createInBulkQbPaymentTermsBulkPost<false>({
                //@ts-ignore
                body: values.customer_ids.map((customerId) => ({
                    accounting_system_id: qbAccounts[0]?.id,
                    invoice_customer_id: customerId,
                    allow_ach: values.allow_ach,
                    allow_credit_card: values.allow_credit_card,
                    credit_card_fee: values.credit_card_fee,
                    quickbooks_invoice_net_terms_id: values.quickbooks_invoice_net_terms?.value || null,
                    quickbooks_invoice_net_terms_name: values.quickbooks_invoice_net_terms?.label || null,
                })),
            });

            setIsPaymentTermDialogOpen(false);
            getPaymentTerms();
        } catch (error) {
            console.error('Error submitting payment term:', error);
        }
    };

    const ActionPaymentTermsButton = (
        <Dialog open={isPaymentTermDialogOpen} onOpenChange={(open) => {
            if (open) {
                // Reset form with default values when opening via button
                paymentTermForm.reset({
                    allow_ach: false,
                    allow_credit_card: false,
                    credit_card_fee: '0',
                    customer_ids: [],
                    quickbooks_invoice_net_terms: undefined
                });
            }
            setIsPaymentTermDialogOpen(open);
        }}>
            <DialogTrigger asChild>
                <Button size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Payment Term
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>Add Payment Term</DialogTitle>
                <DialogDescription />
                <Form {...paymentTermForm}>
                    <form onSubmit={paymentTermForm.handleSubmit(onSubmitPaymentTerm)} className="space-y-4">
                        <div className="max-h-[60vh] overflow-y-auto pr-2 pl-2">
                            <FormField
                                control={paymentTermForm.control}
                                name="customer_ids"
                                render={() => (
                                    <FormItem className="mb-4">
                                        <FormLabel>Customers</FormLabel>
                                        <FormControl>
                                            <Controller
                                                name="customer_ids"
                                                control={paymentTermForm.control}
                                                render={({ field }) => (
                                                    <Select
                                                        isMulti
                                                        options={customers.map((customer) => ({
                                                            value: customer.id || '',
                                                            label: customer.name,
                                                        }))}
                                                        value={field.value.map((id) => ({
                                                            value: id,
                                                            label: customers.find((c) => c.id === id)?.name || id,
                                                        }))}
                                                        onChange={(newValue) => {
                                                            field.onChange(newValue.map((v) => v.value));
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        <FormDescription>Select the customers for these payment terms</FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={paymentTermForm.control}
                                name="allow_ach"
                                render={({ field }) => (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-2 rounded-md border p-4">
                                        <FormControl>
                                            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                        </FormControl>
                                        <div className="space-y-1 leading-none">
                                            <FormLabel>Allow ACH</FormLabel>
                                            <FormDescription>Allow ACH payments</FormDescription>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={paymentTermForm.control}
                                name="allow_credit_card"
                                render={({ field }) => (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                        <FormControl>
                                            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                        </FormControl>
                                        <div className="space-y-1 leading-none">
                                            <FormLabel>Allow Credit Card</FormLabel>
                                            <FormDescription>Allow credit card payments</FormDescription>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={paymentTermForm.control}
                                name="credit_card_fee"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Custom Credit Card Processing Fee (%)</FormLabel>
                                        <FormControl>
                                            <Input type="number" step="0.001" {...field} />
                                        </FormControl>
                                        <FormDescription>Custom credit card processing fee (in percentage)</FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={paymentTermForm.control}
                                name="quickbooks_invoice_net_terms"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>QuickBooks Net Terms</FormLabel>
                                        <FormControl>
                                            <Select
                                                options={
                                                    qbData.net_terms_options?.map((term) => ({
                                                        value: term.id,
                                                        label: term.name,
                                                    })) || []
                                                }
                                                value={field.value}
                                                onChange={field.onChange}
                                                isClearable
                                            />
                                        </FormControl>
                                        <FormDescription>Select QuickBooks Net Terms</FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="pt-4 border-t">
                            <Button type="submit" className="w-full">
                                Submit
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    const reportFormatSchema = z.object({
        report_zero_charge: z.boolean(),
        email_to_cc_bill: z.string().email().optional().or(z.literal('')),
        customer_memo: z.string().max(1000).optional(),
        customer_ids: z.array(z.string()).nonempty('At least one customer must be selected'),
    });

    const reportFormatForm = useForm<z.infer<typeof reportFormatSchema>>({
        resolver: zodResolver(reportFormatSchema),
        defaultValues: {
            report_zero_charge: false,
            email_to_cc_bill: '',
            customer_memo: '',
            customer_ids: [],
        },
    });

    const onSubmitReportFormat = async (values: z.infer<typeof reportFormatSchema>) => {
        try {
            // Find existing report formats for the selected customers
            const existingFormatsToDelete = reportFormats.filter((format) => values.customer_ids.includes(format.invoice_customer_id)).map((format) => format.id!);

            // Only delete formats for the customers being updated
            if (existingFormatsToDelete.length > 0) {
                await deleteInBulkQbInvoiceFormatBulkDelete<false>({
                    body: existingFormatsToDelete,
                });
            }

            // Create new formats for the selected customers
            await createInBulkQbInvoiceFormatBulkPost<false>({
                //@ts-ignore
                body: values.customer_ids.map((customerId) => ({
                    accounting_system_id: qbAccounts[0]?.id || '',
                    invoice_customer_id: customerId,
                    report_zero_charge: values.report_zero_charge,
                    email_to_cc_bill: values.email_to_cc_bill || null,
                    customer_memo: values.customer_memo || null,
                })),
            });

            setIsReportFormatDialogOpen(false);
            getReportFormats();
        } catch (error) {
            console.error('Error submitting report format:', error);
        }
    };

    const ActionReportFormatButton = (
        <Dialog open={isReportFormatDialogOpen} onOpenChange={(open) => {
            if (open) {
                // Reset form with default values when opening via button
                reportFormatForm.reset({
                    report_zero_charge: false,
                    email_to_cc_bill: '',
                    customer_memo: '',
                    customer_ids: []
                });
            }
            setIsReportFormatDialogOpen(open);
        }}>
            <DialogTrigger asChild>
                <Button size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Report Format
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] max-h-[80vh] overflow-y-auto">
                <DialogTitle>Add Report Format</DialogTitle>
                <DialogDescription />
                <Form {...reportFormatForm}>
                    <form onSubmit={reportFormatForm.handleSubmit(onSubmitReportFormat)} className="space-y-8">
                        <FormField
                            control={reportFormatForm.control}
                            name="customer_ids"
                            render={() => (
                                <FormItem>
                                    <FormLabel>Customers</FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="customer_ids"
                                            control={reportFormatForm.control}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti
                                                    options={customers.map((customer) => ({
                                                        value: customer.id || '',
                                                        label: customer.name,
                                                    }))}
                                                    value={field.value.map((id) => ({
                                                        value: id,
                                                        label: customers.find((c) => c.id === id)?.name || id,
                                                    }))}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue.map((v) => v.value));
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormDescription>Select the customers for this report format</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="report_zero_charge"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                    <FormControl>
                                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>Report Zero Charges</FormLabel>
                                        <FormDescription>Include zero charges in the report</FormDescription>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="email_to_cc_bill"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email To CC Bill</FormLabel>
                                    <FormControl>
                                        <Input placeholder="email@example.com" {...field} />
                                    </FormControl>
                                    <FormDescription>Email address to CC on the bill</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="customer_memo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Customer Memo</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>Additional memo for the customer</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Submit</Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
    const customerMappingFormSchema = z.object({
        customer_mappings: z.array(
            z.object({
                customer_id: z.string().nonempty('Customer is required'),
                qb_customer_id: z.string().nonempty('QuickBooks customer is required'),
            }),
        ),
    });

    const customerMappingForm = useForm<z.infer<typeof customerMappingFormSchema>>({
        resolver: zodResolver(customerMappingFormSchema),
        defaultValues: {
            customer_mappings: [{ customer_id: '', qb_customer_id: '' }],
        },
    });

    useEffect(() => {
        if (customerMappings.length > 0) {
            customerMappingForm.reset({
                customer_mappings: customerMappings.map((mapping) => ({
                    customer_id: mapping.invoice_customer_id || '',
                    qb_customer_id: mapping.quickbooks_customer_id || '',
                })),
            });
        }
    }, [customerMappings]);

    const {
        fields: customerMappingFields,
        append: appendCustomerMapping,
        remove: removeCustomerMapping,
    } = useFieldArray({
        control: customerMappingForm.control,
        name: 'customer_mappings',
    });
    const onSubmitCustomerMapping = async (values: z.infer<typeof customerMappingFormSchema>) => {
        try {
            await deleteInBulkQbCustomersBulkDelete<false>({
                body: customerMappings.map((mapping) => mapping.id!),
            });

            await createInBulkQbCustomersBulkPost<false>({
                body: values.customer_mappings.map((mapping) => ({
                    company_id: qbAccounts[0]?.id || '',
                    invoice_customer_id: mapping.customer_id,
                    quickbooks_customer_id: mapping.qb_customer_id,
                    quickbooks_name: qbData.customer_mappings?.find((c) => c.id === mapping.qb_customer_id)?.name || '',
                })),
            });

            setIsCustomerMappingDialogOpen(false);
            getCustomerMappings();
        } catch (error) {
            console.error('Error submitting customer mapping:', error);
        }
    };

    const ActionCustomerMappingButton = (
        <Dialog open={isCustomerMappingDialogOpen} onOpenChange={setIsCustomerMappingDialogOpen}>
            <DialogTrigger asChild>
                <Button size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Customer Mapping
                </Button>
            </DialogTrigger>
            <DialogContent className="max-h-[80vh] overflow-y-auto">
                <div className="space-y-4">
                    <DialogTitle>Add Customer Mapping</DialogTitle>
                    <DialogDescription>Map Rails customers to QuickBooks customers</DialogDescription>
                    <Form {...customerMappingForm}>
                        <form onSubmit={customerMappingForm.handleSubmit(onSubmitCustomerMapping)} className="space-y-8">
                            <div className="space-y-4">
                                {customerMappingFields.map((field, index) => (
                                    <div key={field.id} className="flex items-center space-x-4">
                                        <FormField
                                            control={customerMappingForm.control}
                                            name={`customer_mappings.${index}.customer_id`}
                                            render={({ field }) => (
                                                <FormItem className="flex-1">
                                                    <FormLabel>Rails Customer</FormLabel>
                                                    <Select
                                                        options={customers.map((customer) => ({
                                                            value: customer.id || '',
                                                            label: customer.name,
                                                        }))}
                                                        value={field.value ? { value: field.value, label: customers.find((c) => c.id === field.value)?.name || field.value } : null}
                                                        onChange={(newValue) => field.onChange(newValue ? newValue.value : '')}
                                                        isClearable
                                                    />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={customerMappingForm.control}
                                            name={`customer_mappings.${index}.qb_customer_id`}
                                            render={({ field }) => (
                                                <FormItem className="flex-1">
                                                    <FormLabel>QuickBooks Customer</FormLabel>
                                                    <Select
                                                        options={
                                                            qbData.customer_mappings?.map((customer) => ({
                                                                value: customer.id,
                                                                label: customer.name,
                                                            })) || []
                                                        }
                                                        value={
                                                            field.value
                                                                ? { value: field.value, label: qbData.customer_mappings?.find((c) => c.id === field.value)?.name || field.value }
                                                                : null
                                                        }
                                                        onChange={(newValue) => field.onChange(newValue ? newValue.value : '')}
                                                        isClearable
                                                    />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="button" onClick={() => removeCustomerMapping(index)} variant="ghost" className="p-0 h-10 w-10 self-end">
                                            <XCircleIcon className="h-5 w-5" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-between">
                                <Button type="button" onClick={() => appendCustomerMapping({ customer_id: '', qb_customer_id: '' })}>
                                    Add Another Mapping
                                </Button>
                                <Button type="submit">Submit</Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );

    const serviceCategoryFormSchema = z.object({
        service_categories: z.array(
            z.object({
                name: z.string().nonempty('Rails charge is required'),
                quickbooks_name: z.string().nonempty('QuickBooks service is required'),
                charge_type_id: z.string().optional(),
                quickbooks_charge_id: z.string(),
            }),
        ),
    });

    const serviceCategoryForm = useForm<z.infer<typeof serviceCategoryFormSchema>>({
        defaultValues: {
            service_categories: [],
        },
    });

    useEffect(() => {
        if (serviceCategories.length > 0 && qbAccounts.length > 0) {
            const defaultValues = {
                service_categories: serviceCategories.map((category) => transformServiceCategoryForForm(category, chargeTypes)),
            };
            serviceCategoryForm.reset(defaultValues);
        }
    }, [serviceCategories, qbAccounts, chargeTypes, serviceCategoryForm]);

    const {
        fields: serviceCategoryFields,
        append: appendServiceCategoryRaw,
        remove: removeServiceCategory,
    } = useFieldArray({
        control: serviceCategoryForm.control,
        name: 'service_categories',
    });

    const appendServiceCategory = () => {
        appendServiceCategoryRaw({
            name: '',
            quickbooks_name: '',
            charge_type_id: '',
            quickbooks_charge_id: '',
        });
    };

    const onSubmitServiceCategory = async (values: z.infer<typeof serviceCategoryFormSchema>) => {
        try {
            // Delete existing categories
            await deleteInBulkQbChargesBulkDelete<false>({
                body: serviceCategories.map((category) => category.id!),
            });

            // Create new categories, ensuring charge_type_id is properly mapped
            await createInBulkQbChargesBulkPost<false>({
                body: values.service_categories
                    .map((category) => {
                        // Find the charge type that matches the name
                        const chargeType = chargeTypes.find((ct) => ct.charge_on_invoice === category.name);

                        return {
                            company_id: qbAccounts[0]?.id || '',
                            charge_type_id: chargeType?.id || '', // This should never be empty in practice
                            quickbooks_charge_id: category.quickbooks_charge_id,
                            quickbooks_name: category.quickbooks_name,
                        };
                    })
                    .filter((category) => category.charge_type_id !== ''), // Filter out any entries without a valid charge_type_id
            });

            setIsServiceCategoryDialogOpen(false);
            getServiceCategories();
        } catch (error) {
            console.error('Error submitting service category:', error);
        }
    };

    const ActionServiceCategoryButton = (
        <Dialog open={isServiceCategoryDialogOpen} onOpenChange={setIsServiceCategoryDialogOpen}>
            <DialogTrigger asChild>
                <Button size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Service Category
                </Button>
            </DialogTrigger>
            <DialogContent className="max-h-[80vh] overflow-y-auto">
                <div className="space-y-4">
                    <DialogTitle>Add Service Category</DialogTitle>
                    <DialogDescription>Map Rails service categories to QuickBooks service categories</DialogDescription>
                    <Form {...serviceCategoryForm}>
                        <form onSubmit={serviceCategoryForm.handleSubmit(onSubmitServiceCategory)} className="space-y-8">
                            <div className="space-y-4">
                                {serviceCategoryFields.map((field, index) => (
                                    <div key={field.id} className="flex items-center space-x-4">
                                        <FormField
                                            control={serviceCategoryForm.control}
                                            name={`service_categories.${index}.name`}
                                            render={({ field }) => (
                                                <FormItem className="flex-1">
                                                    <FormLabel>Rails Charge</FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            options={chargeTypes.map((chargeType) => ({
                                                                value: chargeType.charge_on_invoice,
                                                                label: chargeType.charge_on_invoice,
                                                            }))}
                                                            value={field.value ? { value: field.value, label: field.value } : null}
                                                            onChange={(newValue) => {
                                                                field.onChange(newValue ? newValue.value : '');
                                                                // Also store the charge_type_id when selection changes
                                                                const selectedChargeType = chargeTypes.find((ct) => ct.charge_on_invoice === newValue?.value);
                                                                serviceCategoryForm.setValue(`service_categories.${index}.charge_type_id`, selectedChargeType?.id || '');
                                                            }}
                                                            isClearable={false}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={serviceCategoryForm.control}
                                            name={`service_categories.${index}.quickbooks_name`}
                                            render={({ field }) => (
                                                <FormItem className="flex-1">
                                                    <FormLabel>QuickBooks Service</FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            options={
                                                                qbData.service_category_options?.map((category) => ({
                                                                    value: category.id,
                                                                    label: category.name,
                                                                })) || []
                                                            }
                                                            value={
                                                                field.value
                                                                    ? { value: field.value, label: qbData.service_category_options?.find((c) => c.id === field.value)?.name || field.value }
                                                                    : null
                                                            }
                                                            onChange={(newValue) => {
                                                                const selectedCategory = qbData.service_category_options?.find((c) => c.id === newValue?.value);
                                                                field.onChange(selectedCategory?.name || '');

                                                                serviceCategoryForm.setValue(`service_categories.${index}.quickbooks_charge_id`, newValue?.value || '');
                                                            }}
                                                            isClearable={false}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="button" onClick={() => removeServiceCategory(index)} variant="ghost" className="p-0 h-10 w-10 self-end mb-6">
                                            <XCircleIcon className="h-5 w-5" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-between">
                                <Button type="button" onClick={appendServiceCategory}>
                                    Add Another Service Category
                                </Button>
                                <Button type="submit">Submit</Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );

    const ConnectAccountingSystemButton = () => {
        const isConnected = qbAccounts.length > 0 && isConnectionActive(qbAccounts[0]?.api_key_last_refreshed);

        return (
            <div className="flex items-center gap-2">
                {qbAccounts.length > 0 && (
                    <div className="flex items-center gap-2">
                        <CircleIcon className={`h-3 w-3 ${isConnected ? 'text-green-500 fill-green-500 animate-pulse' : 'text-red-500 fill-red-500 animate-pulse'}`} />
                        <span className="text-sm text-muted-foreground">{isConnected ? 'Connected' : 'Disconnected'}</span>
                    </div>
                )}
                <Button
                    onClick={async () => {
                        const { data, error } = await quickbooksAuthUrlQbQuickbooksAuthUrlGet();
                        if (data) {
                            window.open(data, '_blank');
                        } else if (error) {
                            console.error('Error connecting to QuickBooks:', error);
                        }
                        setIsQuickBooksSuccessDialogOpen(true);
                    }}
                    className={loading ? 'animate-pulse' : ''}
                    //disabled={isConnected}
                >
                    {loading ? (
                        'Loading...'
                    ) : (
                        <>
                            <Plus className="mr-2 h-4 w-4" /> QuickBooks
                        </>
                    )}
                </Button>
            </div>
        );
    };

    const handlePaymentTermRowClick = (row: any) => {
        const rowCustomers = row.original?.customers || [];
        
        const customerIds = customers
            .filter(customer => rowCustomers.includes(customer.name))
            .map(customer => customer.id!);

        const netTermsOption = qbData.net_terms_options?.find(
            term => term.name === row.original.quickbooks_invoice_net_terms_name
        );

        paymentTermForm.reset({
            allow_ach: row.original.allow_ach,
            allow_credit_card: row.original.allow_credit_card,
            credit_card_fee: row.original.credit_card_fee === '0E-10' ? '0' : row.original.credit_card_fee,
            customer_ids: customerIds,
            quickbooks_invoice_net_terms: netTermsOption ? {
                value: netTermsOption.id,
                label: netTermsOption.name
            } : undefined
        });

        setIsPaymentTermDialogOpen(true);
    };

    const handleReportFormatRowClick = (row: any) => {
        const rowCustomers = row.original?.customers || [];
        
        const customerIds = customers
            .filter(customer => rowCustomers.includes(customer.name))
            .map(customer => customer.id!);

        reportFormatForm.reset({
            report_zero_charge: row.original.report_zero_charge,
            email_to_cc_bill: row.original.email_to_cc_bill || '',
            customer_memo: row.original.customer_memo || '',
            customer_ids: customerIds
        });

        setIsReportFormatDialogOpen(true);
    };

    return (
        <div className="container mx-auto p-8">
            <>
                <div className="flex justify-end mb-4">
                    <ConnectAccountingSystemButton />
                </div>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Payment Terms</h2>
                </div>
                <DataTable columns={paymentTermColumns} data={paymentTermGroupedData} loading={loading} ActionButton={ActionPaymentTermsButton} showActionButtonInToolbar={true} rowOnClick={handlePaymentTermRowClick} />
                <h2 className="text-xl font-semibold mb-4 mt-8">Report Formats</h2>
                <DataTable columns={reportFormatColumns} data={reportFormatGroupedData} loading={loading} ActionButton={ActionReportFormatButton} showActionButtonInToolbar={true} rowOnClick={handleReportFormatRowClick} />
                <h2 className="text-xl font-semibold mb-4 mt-8">Customer Mappings</h2>
                <DataTable columns={customerMappingColumns} data={customerMappings} loading={loading} ActionButton={ActionCustomerMappingButton} showActionButtonInToolbar={true} />
                <h2 className="text-xl font-semibold mb-4 mt-8">Service Categories</h2>
                <DataTable columns={serviceCategoryColumns} data={serviceCategories} loading={loading} ActionButton={ActionServiceCategoryButton} showActionButtonInToolbar={true} />
            </>

            <Dialog open={isAccountingSystemDialogOpen} onOpenChange={setIsAccountingSystemDialogOpen}>
                <DialogContent>
                    <DialogTitle>Your QuickBooks account has been connected</DialogTitle>
                    <p>Please close this dialog and refresh the page.</p>
                    <div className="flex justify-end">
                        <Button onClick={() => setIsAccountingSystemDialogOpen(false)}>Close</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={isQuickBooksSuccessDialogOpen} onOpenChange={setIsQuickBooksSuccessDialogOpen}>
                <DialogContent>
                    <DialogTitle>Your QuickBooks account has been connected</DialogTitle>
                    <p>Please close this dialog.</p>
                    <div className="flex justify-end">
                        <Button onClick={() => setIsQuickBooksSuccessDialogOpen(false)}>Close</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AccountingSystems;
