// inspired by react-js-cron but using shadcn components
import * as z from 'zod';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { range } from 'lodash';
import { converter } from 'react-js-cron';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormItem, FormControl, FormMessage } from './form.tsx';
import { scheduleTypeOptions, dayOptions, monthOptions, cronUnits, billingPeriodCutoffTimeMinute, billingPeriodCutoffTimeHour } from '../../consts/vars';
import { getDropdownValueObj, getDropdownValues, getOrdinal } from '../../consts/func';

// ************************************************************************************
// start of lib code copied from https://github.com/xrutayisire/react-js-cron/
// ************************************************************************************

function convertStringToNumber(str) {
    const parseIntValue = parseInt(str, 10);
    const numberValue = Number(str);

    return parseIntValue === numberValue ? numberValue : NaN;
}

function outOfRange(values, unit) {
    const first = values[0];
    const last = values[values.length - 1];

    if (first < unit.min) {
        return first;
    }
    if (last > unit.max) {
        return last;
    }

    return undefined;
}

export function sort(array) {
    array.sort((a, b) => a - b);

    return array;
}

export function dedup(array) {
    const result = [];

    array.forEach((i) => {
        if (result.indexOf(i) < 0) {
            result.push(i);
        }
    });

    return result;
}

function fixSunday(values, unit) {
    let newValues = values;
    if (unit.type === 'week-days') {
        newValues = values.map((value) => {
            if (value === 7) {
                return 0;
            }

            return value;
        });
    }

    return newValues;
}

function applyInterval(values, step) {
    let newValues = values;
    if (step) {
        const minVal = values[0];

        newValues = values.filter((value) => value % step === minVal % step || value === minVal);
    }

    return newValues;
}

function replaceAlternatives(str, min, alt) {
    let newStr = str;
    if (alt) {
        newStr = str.toUpperCase();

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < alt.length; i++) {
            newStr = str.replace(alt[i], `${i + min}`);
        }
    }
    return newStr;
}

function parseStep(step, unit) {
    if (typeof step !== 'undefined') {
        const parsedStep = convertStringToNumber(step);

        if (Number.isNaN(parsedStep) || parsedStep < 1) {
            throw new Error(`Invalid interval step value "${step}" for ${unit.type}`);
        }

        return parsedStep;
    }

    return undefined;
}

function parseRange(rangeStr, context, unit) {
    const subparts = rangeStr.split('-');

    if (subparts.length === 1) {
        const value = convertStringToNumber(subparts[0]);

        if (Number.isNaN(value)) {
            throw new Error(`Invalid value "${context}" for ${unit.type}`);
        }

        return [value];
    }
    if (subparts.length === 2) {
        const minValue = convertStringToNumber(subparts[0]);
        const maxValue = convertStringToNumber(subparts[1]);

        if (Number.isNaN(minValue) || Number.isNaN(maxValue)) {
            throw new Error(`Invalid value "${context}" for ${unit.type}`);
        }

        // Fix to allow equal min and max range values
        // cf: https://github.com/roccivic/cron-converter/pull/15
        if (maxValue < minValue) {
            throw new Error(`Max range is less than min range in "${rangeStr}" for ${unit.type}`);
        }

        // SB: Had to manually adjust to get range working correctly
        return range(minValue, maxValue + 1);
    }
    throw new Error(`Invalid value "${rangeStr}" for ${unit.type}`);
}

function parsePartString(str, unit) {
    if (str === '*' || str === '*/1') {
        return [];
    }

    const values = sort(
        dedup(
            fixSunday(
                replaceAlternatives(str, unit.min, unit.alt)
                    .split(',')
                    .map((value) => {
                        const valueParts = value.split('/');

                        if (valueParts.length > 2) {
                            throw new Error(`Invalid value "${str} for "${unit.type}"`);
                        }

                        let parsedValues;
                        const left = valueParts[0];
                        const right = valueParts[1];

                        if (left === '*') {
                            parsedValues = range(unit.min, unit.max);
                        } else {
                            parsedValues = parseRange(left, str, unit);
                        }

                        const step = parseStep(right, unit);
                        const intervalValues = applyInterval(parsedValues, step);

                        return intervalValues;
                    })
                    .flat(),
                unit,
            ),
        ),
    );

    const value = outOfRange(values, unit);

    if (typeof value !== 'undefined') {
        throw new Error(`Value "${value}" out of range for ${unit.type}`);
    }

    // Prevent to return full array
    // If all values are selected we don't want any selection visible
    if (values.length === unit.total) {
        return [];
    }

    return values;
}

function parseCronString(str) {
    if (typeof str !== 'string') {
        throw new Error('Invalid cron string');
    }

    const parts = str.replace(/\s+/g, ' ').trim().split(' ');

    if (parts.length === 5) {
        return parts.map((partStr, idx) => parsePartString(partStr, cronUnits[idx]));
    }

    throw new Error('Invalid cron string format');
}

function getPeriodFromCronParts(cronParts) {
    if (cronParts[3].length > 0) {
        return 'year';
    }
    if (cronParts[2].length > 0) {
        return 'month';
    }
    if (cronParts[4].length > 0) {
        return 'week';
    }
    if (cronParts[1].length > 0) {
        return 'day';
    }
    if (cronParts[0].length > 0) {
        return 'hour';
    }
    return 'minute';
}

// ************************************************************************************
// end of lib code copied from https://github.com/xrutayisire/react-js-cron/
// ************************************************************************************

const CronSchedule = ({ className, setCronString, cronValidationRef, initialCronString, allowSetHourly = true }) => {
    const [scheduleType, setScheduleType] = useState();
    const minuteOptions = range(60).map((num) => ({ label: num, value: num }));
    const hourOptions = range(24).map((num) => ({ label: num, value: num }));
    const monthDateOptions = range(31).map((num) => ({ label: getOrdinal(num + 1), value: num + 1 }));

    const scheduleTypeEnum = z.enum(scheduleTypeOptions.map((scheduleTypeOption) => scheduleTypeOption.value));

    const props = {
        schedule_type: z.object({
            label: z.string(),
            value: z.string(),
        }),
        month: z.array(
            z.object({
                label: z.string(),
                value: z.string(),
            }),
        ),
        date: z.array(
            z.object({
                label: z.string(),
                value: z.number(),
            }),
        ),
        day: z.array(
            z.object({
                label: z.string(),
                value: z.number(),
            }),
        ),
        hour: z.array(
            z.object({
                label: z.number(),
                value: z.number(),
            }),
        ),
        minute: z.array(
            z.object({
                label: z.number(),
                value: z.number(),
            }),
        ),
    };

    const yearTypeSchema = z.object({
        schedule_type: z.literal(scheduleTypeEnum.enum.year),
        month: props.month,
        date: props.date,
        day: props.day,
        hour: props.hour,
        minute: props.minute,
    });

    const monthTypeSchema = z.object({
        schedule_type: z.literal(scheduleTypeEnum.enum.month),
        date: props.date,
        day: props.day,
        hour: props.hour,
        minute: props.minute,
    });

    const weekTypeSchema = z.object({
        schedule_type: z.literal(scheduleTypeEnum.enum.week),
        day: props.day,
        hour: props.hour,
        minute: props.minute,
    });

    const dayTypeSchema = z.object({
        schedule_type: z.literal(scheduleTypeEnum.enum.day),
        hour: props.hour,
        minute: props.minute,
    });

    // default values
    const cronScheduleDefaultSchema = z.object({
        schedule_type: props.schedule_type,
    });

    const cronScheduleConditionalSchema = z.discriminatedUnion('schedule_type', [yearTypeSchema, monthTypeSchema, weekTypeSchema, dayTypeSchema]);
    const cronScheduleSchema = cronScheduleDefaultSchema
        .passthrough()
        .transform((obj) => ({ ...obj, schedule_type: obj.schedule_type.value }))
        .pipe(cronScheduleConditionalSchema);

    const formProps = useForm({
        resolver: zodResolver(cronScheduleSchema),
    });

    useEffect(() => {
        if (initialCronString) {
            const cronParts = parseCronString(initialCronString);
            const period = getPeriodFromCronParts(cronParts);

            formProps.reset({
                schedule_type: getDropdownValueObj(period, scheduleTypeOptions),
                minute: getDropdownValues(cronParts[0], minuteOptions),
                hour: getDropdownValues(cronParts[1], hourOptions),
                date: getDropdownValues(cronParts[2], monthDateOptions),
                day: getDropdownValues(cronParts[4], dayOptions),
                month: getDropdownValues(cronParts[3], monthOptions),
            });

            setScheduleType(period);
        }
    }, [initialCronString]);

    const { watch, trigger } = formProps;

    useEffect(() => {
        if (cronValidationRef && cronValidationRef.current) {
            cronValidationRef.current = trigger;
        }
    }, [trigger]);

    useEffect(() => {
        const subscription = watch((value) => {
            const cronString = converter.getCronStringFromValues(
                value.schedule_type.value, // period: 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'reboot'
                value.month?.map((x) => x.value), // months: number[] | undefined
                value.date?.map((x) => x.value), // monthDays: number[] | undefined
                value.day?.map((x) => x.value), // weekDays: number[] | undefined
                allowSetHourly ? value.hour?.map((x) => x.value) : [23], // Changed from billingPeriodCutoffTimeHour
                allowSetHourly ? value.minute?.map((x) => x.value) : [59], // Changed from billingPeriodCutoffTimeMinute
                false, // humanizeValue?: boolean
            );
            setCronString(cronString);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <Form {...formProps}>
            <div className={`grid items-center space-x-2 ${className}`}>
                <div className="flex items-center space-x-2">
                    <span className="text-base">Every </span>
                    <div>
                        <FormField
                            control={formProps.control}
                            name="schedule_type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-48"
                                            options={scheduleTypeOptions}
                                            onChange={(e) => {
                                                setScheduleType(e.value);
                                                field.onChange(e);
                                            }}
                                            placeholder="period"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                {scheduleType === 'hour' && (
                    <div className="mt-2 flex items-center space-x-2">
                        <span className="text-base">at</span>
                        <FormField
                            control={formProps.control}
                            name="minute"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-48"
                                            isMulti
                                            options={minuteOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder=""
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <span className="text-base">minute(s)</span>
                    </div>
                )}
                {scheduleType === 'year' && (
                    <div className="mt-2 flex items-center space-x-2">
                        <span className="text-base">in</span>
                        <FormField
                            control={formProps.control}
                            name="month"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-fit"
                                            isMulti
                                            options={monthOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder="month(s)"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
                {(scheduleType === 'year' || scheduleType === 'month') && (
                    <div className="mt-2 flex items-center space-x-2">
                        <span className="text-base">on</span>
                        <FormField
                            control={formProps.control}
                            name="date"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-fit"
                                            isMulti
                                            options={monthDateOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder="date(s)"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
                {(scheduleType === 'year' || scheduleType === 'month' || scheduleType === 'week') && (
                    <div className="mt-2 flex items-center space-x-2">
                        {(scheduleType === 'year' || scheduleType === 'month') && <span className="text-base">and</span>}
                        {scheduleType === 'week' && <span className="text-base">on</span>}
                        <FormField
                            control={formProps.control}
                            name="day"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-fit"
                                            isMulti
                                            options={dayOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder="day(s)"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
                {allowSetHourly && (scheduleType === 'year' || scheduleType === 'month' || scheduleType === 'week' || scheduleType === 'day') && (
                    <div className="mt-2 flex items-center space-x-2">
                        <span className="text-base">at</span>
                        <FormField
                            control={formProps.control}
                            name="hour"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-fit"
                                            isMulti
                                            options={hourOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder="hour(s)"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <span className="text-base">:</span>
                        <FormField
                            control={formProps.control}
                            name="minute"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            className="w-fit"
                                            isMulti
                                            options={minuteOptions}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            placeholder="minute(s)"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
            </div>
        </Form>
    );
};

export default CronSchedule;
