import { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { readManyStorageTypesInvoiceStorageChargeGet, refreshStorageTypesInvoiceStorageChargeRefreshPost } from '@/client/services.gen';
import { Button } from '@/components/ui/button';

interface StorageType {
    name: string;
}

const StorageTypes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<StorageType[]>([]);

    const fetchStorageTypes = async () => {
        setIsLoading(true);
        const { data, error } = await readManyStorageTypesInvoiceStorageChargeGet();
        if (error) {
            console.error('Failed to fetch storage types:', error);
        } else {
            setTableData(
                data!.map((item) => ({
                    ...item,
                })),
            );
        }
        setIsLoading(false);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        const { error } = await refreshStorageTypesInvoiceStorageChargeRefreshPost();
        if (error) {
            console.error('Failed to refresh storage types:', error);
        }
        await fetchStorageTypes();
    };

    useEffect(() => {
        fetchStorageTypes();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: { row: any }) => <div className="w-[200px] font-medium">{row.getValue('name')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Types</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage storage types.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && (
                                    <DataTable 
                                        loading={isLoading} 
                                        data={tableData} 
                                        columns={columns} 
                                        isMultiSelectRows={false} 
                                        tableName="storageTypes"
                                        ActionButton={
                                            <Button 
                                                className={isLoading ? 'animate-pulse' : ''} 
                                                onClick={handleRefresh}
                                            >
                                                {isLoading ? 'Refreshing...' : 'Refresh'}
                                            </Button>
                                        }
                                        showActionButtonInToolbar={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default StorageTypes;
