import type { Column } from '@tanstack/table-core';
import { Input } from '@/components/ui/input';
import { 
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue 
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useState, useEffect } from 'react';

interface NumberFilterProps<TData, TValue> {
    column: Column<TData, TValue>;
    title: string;
}

const EPSILON = 0.01;

export function DataTableNumberFilter<TData, TValue>({
    column,
    title
}: NumberFilterProps<TData, TValue>) {
    const [operator, setOperator] = useState<'gt' | 'lt' | 'eq'>('eq');
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        column.columnDef.filterFn = (row, columnId, filterValue) => {
            if (!filterValue) return true;
            
            const rowValue = Number(row.getValue(columnId));
            const { operator, value: filterNum } = filterValue;
            
            if (isNaN(rowValue)) return true;

            switch (operator) {
                case 'gt':
                    return rowValue > Number(filterNum);
                case 'lt':
                    return rowValue < Number(filterNum);
                case 'eq':
                    return Math.abs(rowValue - Number(filterNum)) < EPSILON;
                default:
                    return true;
            }
        };
    }, [column]);

    const handleApplyFilter = () => {
        column.setFilterValue({ operator, value: Number(value) });
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="h-8 border-dashed">
                    {title}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-2">
                <div className="space-y-2">
                    <Select
                        value={operator}
                        onValueChange={(value) => setOperator(value as 'gt' | 'lt' | 'eq')}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select operator" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="gt">Greater than</SelectItem>
                            <SelectItem value="lt">Less than</SelectItem>
                            <SelectItem value="eq">Equal to</SelectItem>
                        </SelectContent>
                    </Select>
                    <Input
                        type="number"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Enter value..."
                    />
                    <div className="flex justify-between">
                        <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                                column.setFilterValue(undefined);
                                setValue('');
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            size="sm"
                            onClick={handleApplyFilter}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
} 