import { useState, useEffect } from 'react';
import { 
  readManyV2WmsShippingMethodsGet
} from '@/client';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import type { ShippingMethod } from '@/client';

export default function ServiceLevelNotification() {
  const [isLoading, setIsLoading] = useState(true);
  const [missingServiceLevels, setMissingServiceLevels] = useState<ShippingMethod[]>([])

  useEffect(() => {
    fetchMappings()
  }, [])

  const fetchMappings = async () => {
      const { data, error } = await readManyV2WmsShippingMethodsGet();
      if (error) {
          console.log(error)
      } else {
          const missing = data!.filter(d => d.service_level_id === null);
          setMissingServiceLevels(missing);
          setIsLoading(false)
      }
  }

  if (isLoading) {
      return (
          <div className="h-screen flex items-center justify-center">
              <p className="text-xl">Loading...</p>
          </div>
      );
  }

  return <div>
    {missingServiceLevels.length > 0 && (
        <div className="mx-8">
            <Alert variant="default" className="space-y-2">
                <AlertTitle className="text-lg font-semibold">
                    Service Level Mappings Missing
                </AlertTitle>
                <AlertDescription>
                    <p className="text-sm mb-3">
                        There {missingServiceLevels.length === 1 ? 'is' : 'are'} {missingServiceLevels.length} unmapped service level{missingServiceLevels.length === 1 ? '' : 's'}. 
                        Without proper mapping, invoices may be generated incorrectly.
                    </p>
                    <div className="mt-4">
                        <a href="/integrations/carriers">
                            <Button variant="outline" size="sm">
                                Configure Service Levels
                            </Button>
                        </a>
                    </div>
                </AlertDescription>
            </Alert>
        </div>
    )}
  </div>
}
