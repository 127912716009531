import React, { useState, useEffect } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Cog8ToothIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Calendar } from '@/components/ui/calendar';
import { format, subWeeks } from 'date-fns';
import { Checkbox } from '@/components/ui/checkbox';
import Spinner from '@/components/ui/loading-spinner';

interface BarChartCardProps {
    title: string;
    description: string;
    data: any[];
    dataKeys: string[];
    xAxisDataKey: string;
    onFetchData: (params: any) => Promise<void>;
    isLoading: boolean;
    error: string | null;
    aggregateOptions: { [key: string]: string };
    columnOptions: { [key: string]: string };
    aggregationTypeOptions: { [key: string]: string };
    dateTypeOptions: { [key: string]: string };
}

export const BarChartCard: React.FC<BarChartCardProps> = ({
    title,
    description,
    data,
    dataKeys,
    xAxisDataKey,
    onFetchData,
    isLoading,
    error,
    aggregateOptions,
    columnOptions,
    aggregationTypeOptions,
    dateTypeOptions,
}) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [colAggBy, setColAggBy] = useState(Object.keys(aggregateOptions)[0]);
    const [colSelect, setColSelect] = useState(Object.keys(columnOptions)[0]);
    const [typeAgg, setTypeAgg] = useState(Object.keys(aggregationTypeOptions)[0]);
    const [typeDate, setTypeDate] = useState(Object.keys(dateTypeOptions)[0]);
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 6));
    const [endDate, setEndDate] = useState(new Date());
    const [nameFilter, setNameFilter] = useState<{ [key: string]: boolean }>({});
    const [nameOptions, setNameOptions] = useState<string[]>([]);

    useEffect(() => {
        if (data) {
            const uniqueNames = Array.from(new Set(data.map((item) => item.name)));
            setNameOptions(uniqueNames);
            setNameFilter(uniqueNames.reduce((acc, name) => ({ ...acc, [name]: true }), {}));
        }
    }, [data]);

    const handleFetchData = () => {
        onFetchData({
            col_agg_by: colAggBy,
            col_select: colSelect,
            type_agg: typeAgg,
            type_date: typeDate,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
        });
        setIsSettingsOpen(false);
    };

    const formatChartData = (data: any[]) => {
        const dataMap: { [key: string]: any } = {};
        data.forEach((item) => {
            if (item['timestampz'] && nameFilter[item['name']]) {
                const date = new Date(item['timestampz']).toISOString().split('T')[0]; // Convert to YYYY-MM-DD
                const aggKey = item['name'];
                const value = parseFloat(item['value'] as string);

                if (!dataMap[date]) {
                    dataMap[date] = { date };
                }

                if (typeAgg === 'count_distinct') {
                    dataMap[date][aggKey] = (dataMap[date][aggKey] || 0) + 1;
                } else {
                    dataMap[date][aggKey] = (dataMap[date][aggKey] || 0) + value;
                }
            }
        });
        return Object.values(dataMap);
    };

    const getDistinctDataKeys = (data: any[] | null) => {
        if (!data) return [];
        const key = 'name';
        return Array.from(new Set(data.map((item) => item[key]))).filter((name) => nameFilter[name as string]);
    };

    const formattedData = formatChartData(data);
    const distinctDataKeys = getDistinctDataKeys(data);

    return (
        <Card className="w-full mt-2 overflow-auto">
            <CardHeader className="relative">
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
                <div className="absolute top-2 right-2 flex space-x-2">
                    <Popover open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
                        <PopoverTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <AdjustmentsHorizontalIcon className="w-6 h-6" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-80" align="end" side="bottom" sideOffset={8}>
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold mb-2">Filter</h3>
                                <div className="space-y-2 max-h-40 overflow-y-auto">
                                    {nameOptions.map((name) => (
                                        <div key={name} className="flex items-center">
                                            <Checkbox
                                                id={`filter-${name}`}
                                                checked={nameFilter[name]}
                                                onCheckedChange={(checked) => {
                                                    setNameFilter((prev) => ({ ...prev, [name]: !!checked }));
                                                }}
                                            />
                                            <label htmlFor={`filter-${name}`} className="ml-2 text-sm">
                                                {name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <Popover open={isSettingsOpen} onOpenChange={setIsSettingsOpen}>
                        <PopoverTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <Cog8ToothIcon className="w-6 h-6" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-80" align="end" side="bottom" sideOffset={8}>
                            <div className="space-y-4">
                                {/* Aggregate By */}
                                <div className="space-y-2">
                                    <label htmlFor="colAggBy" className="block text-sm font-medium text-gray-700">
                                        Aggregate By
                                    </label>
                                    <Select value={colAggBy} onValueChange={setColAggBy}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select aggregate by" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.entries(aggregateOptions).map(([key, value]) => (
                                                <SelectItem key={key} value={key}>
                                                    {value}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Select Column */}
                                <div className="space-y-2">
                                    <label htmlFor="colSelect" className="block text-sm font-medium text-gray-700">
                                        Select Column
                                    </label>
                                    <Select value={colSelect} onValueChange={setColSelect}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select column" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.entries(columnOptions).map(([key, value]) => (
                                                <SelectItem key={key} value={key}>
                                                    {value}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Aggregation Type */}
                                <div className="space-y-2">
                                    <label htmlFor="typeAgg" className="block text-sm font-medium text-gray-700">
                                        Aggregation Type
                                    </label>
                                    <Select value={typeAgg} onValueChange={setTypeAgg}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select aggregation type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.entries(aggregationTypeOptions).map(([key, value]) => (
                                                <SelectItem key={key} value={key}>
                                                    {value}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Date Type */}
                                <div className="space-y-2">
                                    <label htmlFor="typeDate" className="block text-sm font-medium text-gray-700">
                                        Date Type
                                    </label>
                                    <Select value={typeDate} onValueChange={setTypeDate}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select date type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.entries(dateTypeOptions).map(([key, value]) => (
                                                <SelectItem key={key} value={key}>
                                                    {value}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Date Range */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">Date Range</label>
                                    <div className="flex space-x-2">
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button variant="outline">{format(startDate, 'PP')}</Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar mode="single" selected={startDate} onSelect={(date) => date && setStartDate(date)} />
                                            </PopoverContent>
                                        </Popover>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button variant="outline">{format(endDate, 'PP')}</Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar mode="single" selected={endDate} onSelect={(date) => date && setEndDate(date)} />
                                            </PopoverContent>
                                        </Popover>
                                    </div>
                                </div>

                                <Button onClick={handleFetchData} disabled={isLoading}>
                                    {isLoading ? 'Loading...' : 'Fetch Data'}
                                </Button>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </CardHeader>
            <CardContent>
                {error && <p className="text-red-500">{error}</p>}

                {isLoading ? (
                    <div className="flex justify-center items-center h-[400px]">
                        <Spinner size="lg" />
                    </div>
                ) : formattedData ? (
                    <div className="mt-4">
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={xAxisDataKey} />
                                <YAxis width={80} />
                                <Tooltip />
                                <Legend />
                                {distinctDataKeys.map((key, index) => (
                                    <Bar key={key} dataKey={key} fill={`hsl(var(--chart-${index + 1}))`} name={key} />
                                ))}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                ) : (
                    <p>No data available</p>
                )}
            </CardContent>
        </Card>
    );
};
