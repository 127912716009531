import { useState, useEffect, useRef } from 'react';
import {
    statsCarrierBillsStatsGet,
    postConfigCarrierReportExportPost,
    tableDataDownloadCarrierBillsReportsChargeDetailJsonGet,
    postConfigCarrierReportExportEmailPost,
    invoiceDetailCarrierBillsReportsInvoiceDetailGet,
} from '@/client/services.gen';
import { type StatsCarrierBillsStatsGetResponse, Aggregation, Period, ColSelect, ColAggBy, CarrierReportExportType, type InvoiceDetailRow, ColDate } from '@/client/types.gen';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { BarChartCard } from '@/components/ui/charts/bar';
import { DataTable } from '@/components/ui/datatable/data-table';
import { NumericFormat } from 'react-number-format';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const ftpFormSchema = z.object({
    host: z
        .string()
        .min(1, 'Host is required')
        .refine((value) => /^(\d{1,3}\.){3}\d{1,3}$|^[a-zA-Z0-9.-]+$/.test(value), {
            message: 'Enter a valid IP address or hostname',
        }),
    port: z.number().int().min(1).max(65535),
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
    directory: z
        .string()
        .min(1, 'Directory is required')
        .refine((value) => /^(\/[^/ ]*)+\/?$/.test(value), {
            message: 'Please enter a valid Unix-style directory path',
        }),
});

type FtpFormValues = z.infer<typeof ftpFormSchema>;

interface Carrier {
    name: string;
    id: string;
}

export default function CarrierSpend() {
    const [stats, setStats] = useState<StatsCarrierBillsStatsGetResponse>();
    const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailRow[]>([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(subWeeks(new Date(), 1));
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [emailId, setEmailId] = useState<string>('');
    const [reportType] = useState<string>(CarrierReportExportType.CARRIER_CHARGE_DETAIL);
    const [carriers, setCarriers] = useState<{ id: string; name: string }[]>([]);
    const [selectedCarrier, setSelectedCarrier] = useState<string>('ALL_CARRIERS');
    const [selectedDateColumn, setSelectedDateColumn] = useState<ColDate>('INVOICE_DATE');
    const initialFetchDone = useRef(false);

    const form = useForm<FtpFormValues>({
        resolver: zodResolver(ftpFormSchema),
        defaultValues: {
            host: '',
            port: 22,
            username: '',
            password: '',
            directory: '',
        },
    });

    const getDefaultDates = () => ({
        start_date: subWeeks(new Date(), 6).toISOString(),
        end_date: new Date().toISOString(),
    });

    const handleFetchInvoiceDetails = async (params: any) => {
        setIsTableLoading(true);
        const dates = getDefaultDates();
        const response = await invoiceDetailCarrierBillsReportsInvoiceDetailGet({
            query: {
                start_date: params.start_date || dates.start_date,
                end_date: params.end_date || dates.end_date,
            },
        });
        const sortedData = Array.isArray(response.data) ? response.data.sort((a, b) => new Date(b.invoice_date).getTime() - new Date(a.invoice_date).getTime()) : [];
        setInvoiceDetails(sortedData);
        setIsTableLoading(false);
    };

    const handleFetchStats = async (params: any) => {
        setIsChartLoading(true);
        setError(null);
        try {
            const dates = getDefaultDates();
            const response = await statsCarrierBillsStatsGet({
                query: {
                    col_select: params.col_select || 'CHARGE_AMOUNT',
                    col_agg_by: params.col_agg_by || 'CARRIER_NAME',
                    type_agg: params.type_agg || 'sum',
                    type_date: params.type_date || 'week',
                    start_date: params.start_date || dates.start_date,
                    end_date: params.end_date || dates.end_date,
                },
            });

            setStats(response.data);

            if (response.data && Array.isArray(response.data)) {
                const uniqueCarriers = [...new Set(response.data.map((item) => item.name).filter((name) => name && typeof name === 'string' && name.trim() !== ''))]
                    .sort()
                    .map((name) => ({
                        id: name,
                        name: name,
                    }));

                setCarriers(uniqueCarriers);
            }

            return response;
        } catch (error) {
            console.error('Error fetching stats:', error);
            setError('Failed to fetch stats. Please try again.');
            return null;
        } finally {
            setIsChartLoading(false);
        }
    };

    useEffect(() => {
        if (initialFetchDone.current) return;

        const fetchData = async () => {
            const dates = getDefaultDates();
            try {
                await handleFetchStats({
                    col_select: 'CHARGE_AMOUNT',
                    col_agg_by: 'CARRIER_NAME',
                    type_agg: 'sum',
                    type_date: 'week',
                    ...dates,
                });

                await handleFetchInvoiceDetails(dates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        initialFetchDone.current = true;
    }, []);

    const onSubmit = async (values: FtpFormValues) => {
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            postConfigCarrierReportExportPost({
                body: {
                    host: values.host,
                    port: values.port,
                    username: values.username,
                    password: values.password,
                    dir: values.directory,
                },
            });

            setSuccessMessage('FTP configuration has been saved successfully.');
            await fetchFtpConfigs();
        } catch (error) {
            setError('Failed to save FTP configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            postConfigCarrierReportExportEmailPost({
                body: {
                    email: emailId,
                    report_type: reportType as CarrierReportExportType,
                },
            });
            setSuccessMessage('Email configuration has been saved successfully.');
        } catch (error) {
            setError('Failed to save email configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const formatEnumLabel = (enumValue: string): string => {
        return enumValue
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
            .join(' ');
    };

    const dateColumnOptions = Object.values(ColDate).map((value) => ({
        value,
        label: formatEnumLabel(value),
    }));

    const handleDownload = async () => {
        setIsDownloading(true);
        setError(null);
        try {
            const response = await tableDataDownloadCarrierBillsReportsChargeDetailJsonGet({
                query: {
                    start_date: downloadStartDate.toISOString(),
                    end_date: downloadEndDate.toISOString(),
                    carrier: selectedCarrier === 'ALL_CARRIERS' ? null : selectedCarrier,
                    date_type: selectedDateColumn,
                },
            });

            // Create a Blob from the response
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

            // Create a link element and trigger the download
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'carrier_bill_report.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setSuccessMessage('Download successful.');
            }
        } catch (error) {
            setError('Failed to download report. Please try again.');
        } finally {
            setIsDownloading(false);
        }
    };

    const invoiceDetailsColumns = [
        {
            accessorKey: 'carrier',
            header: 'Carrier',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('carrier')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'invoice_number',
            header: 'Invoice Number',
            enableColumnFilter: true,
            filterType: 'text', 
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('invoice_number')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'account_number',
            header: 'Account Number',
            enableColumnFilter: true,
            filterType: 'text', 
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('account_number')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'invoice_date',
            header: 'Invoice Date',
            enableColumnFilter: true,
            filterType: 'date',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{new Date(row.getValue('invoice_date')).toLocaleDateString()}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'total_charge_amount',
            header: 'Total Charge',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('total_charge_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const chargeDetailsColumns = [
        {
            accessorKey: 'charge_description',
            header: 'Charge Description',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('charge_description')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_quantity',
            header: 'Quantity',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('charge_quantity')} displayType="text" thousandSeparator="," decimalScale={0} />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: 'Charge',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('charge_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const InvoiceDetailsTable = () => (
        <div className="mt-8">
            <div className="mb-4">
                <h2 className="text-lg font-bold">List of Invoices</h2>
                <p className="text-sm text-gray-500">Invoice details for all carriers processed in the last 6 weeks</p>
            </div>
            <DataTable
                data={invoiceDetails}
                columns={invoiceDetailsColumns}
                loading={isTableLoading}
                collapsible
                showActionButtonInToolbar
                showActionButtonInCollapsible={false}
                showToolBar
                isDownloadable={true}
                downloadData={invoiceDetails.map(({ charges, ...rest }) => rest)}
                tableName="invoice_details"
                collapsibleContent={({ row }) => {
                    if (row?.charges) {
                        return (
                            <DataTable
                                loading={isTableLoading}
                                data={row.charges}
                                columns={chargeDetailsColumns}
                                isMultiSelectRows={false}
                                showPagination={false}
                                showToolBar={true}
                                showActionButtonInToolbar={false}
                                showActionButtonInCollapsible
                                collapsible={false}
                                isDownloadable={true}
                                downloadData={row.charges}
                                tableName="invoice_details_charges"
                            />
                        );
                    }
                    return null;
                }}
            />
        </div>
    );

    return (
        <div className="p-4">
            <div className="flex justify-end">
                <Dialog>
                    <DialogTrigger asChild>
                        <Button>
                            <div className="pr-2">
                                <ArrowDownTrayIcon className="w-4 h-4" />
                            </div>
                            <div>Export</div>
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Export Reports</DialogTitle>
                        </DialogHeader>
                        <Tabs defaultValue="download">
                            <TabsList className="grid w-full grid-cols-3">
                                <TabsTrigger value="download" className="w-full">
                                    Download
                                </TabsTrigger>
                                <TabsTrigger value="email" className="w-full">
                                    Schedule Email
                                </TabsTrigger>
                                <TabsTrigger value="ftp" className="w-full">
                                    SFTP Dump
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="download">
                                <form className="space-y-4">
                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-gray-700">Date Type</label>
                                        <Select value={selectedDateColumn} onValueChange={setSelectedDateColumn}>
                                            <SelectTrigger>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {dateColumnOptions.map((option) => (
                                                    <SelectItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadStartDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadStartDate} onSelect={(date) => date && setDownloadStartDate(date)} />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadEndDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadEndDate} onSelect={(date) => date && setDownloadEndDate(date)} />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-gray-700">Carrier (Optional)</label>
                                        <Select value={selectedCarrier} onValueChange={setSelectedCarrier}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a carrier" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="ALL_CARRIERS">All Carriers</SelectItem>
                                                {carriers.length > 0 &&
                                                    carriers.map((carrier) => (
                                                        <SelectItem key={carrier.id} value={carrier.id}>
                                                            {carrier.name}
                                                        </SelectItem>
                                                    ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <Button onClick={handleDownload} disabled={isDownloading}>
                                        {isDownloading ? 'Downloading...' : 'Download'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="email">
                                <form onSubmit={handleEmailSubmit} className="space-y-4">
                                    <div className="space-y-2">
                                        <label htmlFor="emailId" className="block text-sm font-medium text-gray-700">
                                            Email ID
                                        </label>
                                        <Input id="emailId" type="text" placeholder="Enter your email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                    </div>
                                    <div className="space-y-2">
                                        <label htmlFor="reportType" className="block text-sm font-medium text-gray-700">
                                            Report Type
                                        </label>
                                        <Input id="reportType" value={reportType} disabled className="bg-gray-100" />
                                    </div>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Schedule Email'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="ftp">
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                                        <FormField
                                            control={form.control}
                                            name="host"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Host</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP host" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="port"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Port</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            type="number"
                                                            placeholder="Enter FTP port"
                                                            {...field}
                                                            onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="username"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Username</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP username" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Password</FormLabel>
                                                    <FormControl>
                                                        <Input type="password" placeholder="Enter FTP password" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="directory"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Directory</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP directory" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div> : null}
                                            {isSubmitting ? 'Submitting...' : 'Set up FTP Export'}
                                        </Button>
                                        {successMessage && <p className="text-green-500">{successMessage}</p>}
                                        {error && <p className="text-red-500">{error}</p>}
                                    </form>
                                </Form>
                            </TabsContent>
                        </Tabs>
                    </DialogContent>
                </Dialog>
            </div>
            <BarChartCard
                title="Carrier Spend and Utilization"
                description="Analyze carrier costs and usage"
                data={stats || []}
                dataKeys={[]}
                xAxisDataKey="date"
                onFetchData={handleFetchStats}
                isLoading={isChartLoading}
                error={error}
                aggregateOptions={Object.fromEntries(Object.entries(ColAggBy).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                columnOptions={Object.fromEntries(Object.entries(ColSelect).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                aggregationTypeOptions={Object.fromEntries(Object.entries(Aggregation).map(([key, value]) => [value, key]))}
                dateTypeOptions={Object.fromEntries(Object.entries(Period).map(([key, value]) => [value, key]))}
                initialParams={{
                    col_select: 'CHARGE_AMOUNT',
                    col_agg_by: 'CARRIER_NAME',
                    type_agg: 'sum',
                    type_date: 'week',
                    start_date: subWeeks(new Date(), 6).toISOString(),
                    end_date: new Date().toISOString(),
                }}
            />
            <InvoiceDetailsTable />
        </div>
    );
}
