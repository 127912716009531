import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from '@/components/common/toaster';
import { AuthProvider } from '@/components/auth/AuthProvider';
import ProtectedRoute from '@/components/ProtectedRoute';
import Login from '@/components/auth/login';
import AccountSettings from '@/components/accountSettings/settings';
import Notifications from '@/components/home/notifications';
import Customers from '@/components/customers/customers';
import NotFound from '@/components/common/NotFound';
import PricingRules from '@/components/invoices/pricingRules/pricingRules';
import Products from '@/components/invoices/products/products';
import Packages from '@/components/invoices/packages';
import Configurations from '@/components/invoices/configurations/configs';
import InvoiceOverview from '@/components/invoices/overview/invoiceOverview';
import StorageFees from '@/components/storage/storageFees';
import NavBar from '@/components/navBar';
import ScrollToHashElement from '@/components/common/scrollToHashElement';
import StorageTypes from '@/components/storage/storageTypes';
import CarrierSpend from '@/components/carriers/spend';
import CarrierExceptions from '@/components/carriers/exceptions';
import InvoiceInsights from '@/components/invoices/invoiceInsights';
import SingleInvoiceDetail from '@/components/invoices/overview/singleInvoiceDetail';
import WmsIntegrations from '@/components/integrations/wms';
import CarrierIntegrations from '@/components/integrations/carriers';
import AccountingSystems from '@/components/integrations/accounting-systems';

const queryClient = new QueryClient()

function App() {
    return (
        <>
            <Toaster />
            <Router>
                <ScrollToHashElement />
                <AuthProvider>
                  <QueryClientProvider client={queryClient}>
                      <Routes>
                          <Route path="*" element={<NotFound />} />

                          <Route element={<NavBar />}>
                              <Route path="/notifications" element={<ProtectedRoute component={Notifications} />} />
                              <Route path="/customers" element={<ProtectedRoute component={Customers} />} />
                              <Route path="/integrations/wms" element={<ProtectedRoute component={WmsIntegrations} />} />
                              <Route path="/integrations/carriers" element={<ProtectedRoute component={CarrierIntegrations} />} />
                              <Route path="/integrations/accounting-systems" element={<ProtectedRoute component={AccountingSystems} />} />
                              <Route path="/settings" element={<ProtectedRoute component={AccountSettings} />} />
                              <Route path="/carriers/spend" element={<ProtectedRoute component={CarrierSpend} />} />
                              <Route path="/carriers/exceptions" element={<ProtectedRoute component={CarrierExceptions} />} />
                              <Route path="/invoices/insights" element={<ProtectedRoute component={InvoiceInsights} />} />
                              <Route path="/invoices/overview" element={<ProtectedRoute component={InvoiceOverview} />} />
                              <Route path="/invoices/:invoiceId" element={<ProtectedRoute component={SingleInvoiceDetail} />} />
                              <Route path="/invoices/pricing/client-charges" element={<ProtectedRoute component={PricingRules} />} />
                              <Route path="/invoices/pricing/products" element={<ProtectedRoute component={Products} />} />
                              <Route path="/invoices/pricing/packages" element={<ProtectedRoute component={Packages} />} />
                              <Route path="/invoices/configurations" element={<ProtectedRoute component={Configurations} />} />
                              <Route path="/storage/types" element={<ProtectedRoute component={StorageTypes} />} />
                              <Route path="/storage/fees" element={<ProtectedRoute component={StorageFees} />} />
                          </Route>
                          <Route path="/" element={<Login />} />
                      </Routes>
                  </QueryClientProvider>
                </AuthProvider>
            </Router>
        </>
    );
}

export default App;
