import { useState, useEffect, useRef } from 'react';
import cronstrue from 'cronstrue';
import * as z from 'zod';
import { Plus } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UsersIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import Select from 'react-select';
import * as servicesGen from '@/client/services.gen';
import type { WarehouseLocationStorageTypeCharge_Output, WarehouseLocationStorageType, InvoiceCustomer } from '@/client/types.gen';
import CronSchedule from '@/components/common/cronSchedule';
import LoadingSpinner from '@/components/ui/loading-spinner';

interface GroupedStorageFee extends WarehouseLocationStorageTypeCharge_Output {
    storage_type_name?: string;
    customer_name?: string;
}

interface GroupedData {
    customer_id: string;
    customer_name: string;
    charges: GroupedStorageFee[];
}

const StorageFees = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<GroupedData[]>([]);
    const [locationData, setLocationData] = useState<WarehouseLocationStorageType[]>([]);
    const [customerData, setCustomerData] = useState<InvoiceCustomer[]>([]);
    const [selectedStorageFee, setSelectedStorageFee] = useState<WarehouseLocationStorageTypeCharge_Output | null>(null);
    const [open, setOpen] = useState(false);
    const [cronString, setCronString] = useState<string>();
    const [isCreateMode, setIsCreateMode] = useState(true);

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const storageFeeSchema = z.object({
        storage_type_id: z.array(dropDownSchema).min(1, "At least one storage type is required"),
        customer_id: z.array(dropDownSchema).min(1, "At least one customer is required"),
        amount: z.coerce.number().min(0, "Amount must be greater than or equal to 0"),
        cadence: z.string().nullable(),
    });

    const formProps = useForm({
        resolver: zodResolver(storageFeeSchema),
        defaultValues: isCreateMode
            ? {
                  storage_type_id: [],
                  customer_id: [],
                  amount: 0,
                  cadence: null,
              }
            : {
                  storage_type_id:
                      locationData
                          .filter((lt) => selectedStorageFee?.storage_type_id === lt.id)
                          .map((lt) => ({
                              label: lt.name,
                              value: lt.id,
                          })) || [],
                  customer_id:
                      customerData
                          .filter((c) => selectedStorageFee?.customer_id === c.id)
                          .map((c) => ({
                              label: c.name,
                              value: c.id,
                          })) || [],
                  amount: selectedStorageFee?.amount || 0,
                  cadence: selectedStorageFee?.cadence,
              },
    });

    const { handleSubmit, reset, control } = formProps;
    const fetchStorageFees = async () => {
        const { data, error } = await servicesGen.readManyStorageTypeChargeInvoiceStorageChargeChargeGet();
        if (error) {
            console.error('Failed to fetch storage fees:', error);
            return null;
        }
        return data;
    };

    const openStorageFeesView = (row: { original: any }) => {
        const charge = row.original;
        const parentFee = tableData.find((data) => data.customer_id === charge.customer_id);

        setSelectedStorageFee(charge);
        setIsCreateMode(false);
        setCronString(charge.cadence);

        reset(
            {
                storage_type_id: [
                    {
                        label: charge.storage_type_name,
                        value: charge.storage_type_id,
                    },
                ],
                customer_id: [
                    {
                        label: parentFee?.customer_name,
                        value: charge.customer_id,
                    },
                ],
                amount: charge.amount || 0,
                cadence: charge.cadence,
            },
            { keepDefaultValues: false },
        );

        setOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [{ data: storageFees }, { data: locations }, { data: customers }] = await Promise.all([
                    servicesGen.readManyStorageTypeChargeInvoiceStorageChargeChargeGet(),
                    servicesGen.readManyStorageTypesInvoiceStorageChargeGet(),
                    servicesGen.readManyV2WmsInvoiceCustomersGet(),
                ]);

                if (!storageFees || !locations || !customers) {
                    throw new Error('Failed to fetch required data');
                }

                const activeCustomers = customers.filter((c) => c.active);

                setLocationData(locations);
                setCustomerData(activeCustomers);

                const groupedData = storageFees.reduce((acc: GroupedData[], fee) => {
                    const customer = activeCustomers.find((c) => c.id === fee.customer_id);
                    const storageType = locations.find((lt) => lt.id === fee.storage_type_id);

                    const existingGroup = acc.find((g) => g.customer_id === fee.customer_id);
                    const feeWithDetails: GroupedStorageFee = {
                        ...fee,
                        storage_type_name: storageType?.name || '',
                        customer_name: customer?.name || '',
                    };

                    if (existingGroup) {
                        existingGroup.charges.push(feeWithDetails);
                    } else {
                        acc.push({
                            customer_id: fee.customer_id,
                            customer_name: customer?.name || '',
                            charges: [feeWithDetails],
                        });
                    }
                    return acc;
                }, []);

                setTableData(groupedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const onSubmit = async (values: any) => {
        if (!cronString) {
            console.error('Cron schedule is required');
            return;
        }

        setIsLoading(true);
        try {
            await Promise.all(
                values.storage_type_id
                    .map((location: { value: string }) =>
                        values.customer_id.map((customer: { value: string }) =>
                            servicesGen.setAmountInvoiceStorageChargeChargePost({
                                // @ts-ignore
                                body: {
                                    storage_type_id: location.value,
                                    customer_id: customer.value,
                                    amount: values.amount,
                                    cadence: cronString,
                                },
                            }),
                        ),
                    )
                    .flat(),
            );

            // Fetch fresh data and reset form
            const storageFees = await fetchStorageFees();
            if (storageFees) {
                const groupedData = storageFees.reduce((acc: GroupedData[], fee) => {
                    const customer = customerData.find((c) => c.id === fee.customer_id);
                    const storageType = locationData.find((lt) => lt.id === fee.storage_type_id);

                    const existingGroup = acc.find((g) => g.customer_id === fee.customer_id);
                    const feeWithDetails: GroupedStorageFee = {
                        ...fee,
                        storage_type_name: storageType?.name || '',
                        customer_name: customer?.name || '',
                    };

                    if (existingGroup) {
                        existingGroup.charges.push(feeWithDetails);
                    } else {
                        acc.push({
                            customer_id: fee.customer_id,
                            customer_name: customer?.name || '',
                            charges: [feeWithDetails],
                        });
                    }
                    return acc;
                }, []);

                setTableData(groupedData);
            }

            // Reset form and close dialog
            reset({
                storage_type_id: [],
                customer_id: [],
                amount: 0,
                cadence: null,
            });
            setCronString(undefined);
            setOpen(false);
        } catch (error) {
            console.error('Failed to save storage fee:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            accessorKey: 'customer_name',
            header: 'Customer',
            cell: ({ row }: { row: any }) => <span className="max-w-[200px] text-wrap">{row.getValue('customer_name')}</span>,
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const collapsibleColumns = [
        {
            accessorKey: 'storage_type_name',
            header: 'Storage Type',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openStorageFeesView(row)}>
                    <a href="#">{row.getValue('storage_type_name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: 'Charge',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[200px] text-wrap">${parseFloat(row.getValue('amount'))?.toFixed(2)}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'cadence',
            header: 'Cadence',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">{row.getValue('cadence') && cronstrue.toString(row.getValue('cadence'), { verbose: true })}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={tableData}
            columns={columns}
            collapsible={true}
            collapsibleContent={({ row }) => {
                if (row?.charges) {
                    return (
                        <DataTable
                            loading={isLoading}
                            data={row.charges}
                            columns={collapsibleColumns}
                            isMultiSelectRows={false}
                            showPagination={false}
                            showToolBar={false}
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible
                            collapsible={false}
                        />
                    );
                }
                return null;
            }}
            ActionButton={
                <Button
                    onClick={() => {
                        setIsCreateMode(true);
                        setOpen(true);
                        setCronString(undefined);
                        reset({
                            storage_type_id: [],
                            customer_id: [],
                            amount: 0,
                            cadence: null,
                        });
                    }}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Create Storage Fee
                </Button>
            }
            isMultiSelectRows={false}
            showActionButtonInToolbar
        />
    );

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex-1 overflow-auto">
                <div className="p-6">
                    <div className="max-w-7xl mx-auto px-4">
                        <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Fees</h1>
                        <h2 className="text-xs text-rails-dark-blue">Manage storage fees.</h2>
                        <div className="py-6">{tableData && <DataTableComponent />}</div>
                    </div>
                </div>
            </div>

            <Dialog open={open} onOpenChange={(isOpen) => {
                if (!isOpen) {
                    setCronString(undefined);
                    reset({
                        storage_type_id: [],
                        customer_id: [],
                        amount: 0,
                        cadence: null,
                    });
                }
                setOpen(isOpen);
            }}>
                <DialogContent className="max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>{isCreateMode ? 'Create New Storage Fee' : 'Edit Storage Fee'}</DialogTitle>
                        <DialogDescription>{isCreateMode ? 'Create a new storage fee by filling out the form below.' : 'Edit the storage fee details below.'}</DialogDescription>
                    </DialogHeader>
                    <div className="mt-4">
                        <Form {...formProps}>
                            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 overflow-y-auto max-h-[70vh] p-4">
                                <FormField
                                    control={control}
                                    name="storage_type_id"
                                    render={({ field }) => (
                                        <FormItem className="mb-6">
                                            <FormLabel>
                                                <span className="flex items-center">
                                                    <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                                                    Locations
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    isMulti
                                                    options={locationData.map((location) => ({
                                                        label: location.name,
                                                        value: location.id,
                                                    }))}
                                                    placeholder="Locations"
                                                    className="p-2"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name="customer_id"
                                    render={({ field }) => (
                                        <FormItem className="mb-6">
                                            <FormLabel>
                                                <div className="flex items-center">
                                                    <UsersIcon className="h-4 w-4 mr-2" />
                                                    Customers
                                                </div>
                                            </FormLabel>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    isMulti
                                                    options={[
                                                        {
                                                            label: "Select All Customers",
                                                            value: "select-all"
                                                        },
                                                        ...customerData.map((customer) => ({
                                                            label: customer.name,
                                                            value: customer.id,
                                                        }))
                                                    ]}
                                                    placeholder="Customer Names"
                                                    className="p-2"
                                                    onChange={(newValue: any) => {
                                                        if (newValue?.some((option: any) => option.value === 'select-all')) {
                                                            const allCustomerIds = customerData.map(customer => ({
                                                                label: customer.name,
                                                                value: customer.id,
                                                            }));
                                                            field.onChange(allCustomerIds);
                                                        } else {
                                                            field.onChange(newValue);
                                                        }
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="space-y-4 mb-6">
                                    <FormLabel>
                                        <div className="flex items-center">
                                            <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                                            Charging Cadence
                                        </div>
                                    </FormLabel>
                                    <CronSchedule
                                        className="mt-2 p-2"
                                        setCronString={setCronString}
                                        initialCronString={isCreateMode ? undefined : selectedStorageFee?.cadence}
                                        allowSetHourly={false}
                                    />
                                </div>

                                <FormField
                                    control={control}
                                    name="amount"
                                    render={({ field }) => (
                                        <FormItem className="mb-6">
                                            <FormLabel>Amount</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="flex justify-between pt-4">
                                    <Button type="submit" disabled={isLoading} className="px-6 py-2">
                                        {isLoading ? (
                                            <>
                                                <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                                {isCreateMode ? 'Creating' : 'Saving'}
                                            </>
                                        ) : isCreateMode ? (
                                            'Create'
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </Form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default StorageFees;
