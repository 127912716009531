import { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import TimeZoneEdit from './timeZoneEdit';
import UploadLogo from './uploadLogo';
import { Button } from '@/components/ui/button';
import ResetPassword from './resetPassword';
import { getTimezonesInternalTimezonesGet, getInvoiceUserGet } from '../../client/services.gen';

const AccountSettings = () => {
    const { currentUser, logout } = useAuth() as { currentUser: any; logout: () => Promise<void> };
    const [currentCompanyData, setCurrentCompanyData] = useState<Record<string, any>>({});
    const [listOfTimeZones, setListOfTimeZones] = useState<Array<{ label: string; value: string }>>([]);
    const [showResetPassword, setShowResetPassword] = useState(false);

    const loadSettingsData = async () => {
        const { data: userData, error: userError } = await getInvoiceUserGet();
        if (userError) {
            console.error('Error fetching user data:', userError);
            return;
        }
        if (userData) {
            setCurrentCompanyData(userData);
        }

        try {
            const { data, error } = await getTimezonesInternalTimezonesGet();
            if (error) {
                console.error('Error fetching timezones:', error);
                return;
            }
            if (data) {
                setListOfTimeZones(
                    data.map((timezone) => ({
                        label: timezone.timezone_name,
                        value: timezone.timezone_name,
                    })),
                );
            }
        } catch (err) {
            console.error('Error fetching timezones:', err);
        }
    };

    useEffect(() => {
        const loadUser = async () => {
            await loadSettingsData();
        };

        loadUser();
    }, [currentUser]);

    const handleLogOut = async (event: React.FormEvent) => {
        event.preventDefault();
        await logout();
    };

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h2 className="text-2xl font-bold text-rails-dark-blue">Settings</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            {/*<div className="pt-8">
                                <h3 className="text-md leading-6 font-extrabold text-rails-dark-blue">Upload Logo</h3>
                                <UploadLogo companyId={currentCompanyData?.company_id} />
                            </div>*/}
                            <div className="pt-8">
                                <h3 className="text-md leading-6 font-extrabold text-rails-dark-blue">Time Zone</h3>
                                <h5 className="text-xs leading-6 text-rails-dark-blue">Current time zone: {currentCompanyData.timezone}</h5>
                                <TimeZoneEdit currentTimeZone={currentCompanyData.timezone} timeZoneOptions={listOfTimeZones} loadSettingsData={loadSettingsData} />
                            </div>
                            <div className="pt-8">
                                <h3 className="text-md leading-6 font-extrabold text-rails-dark-blue">Reset Password</h3>
                                <Button variant="outline" size="sm" className="mt-2" onClick={() => setShowResetPassword(true)}>
                                    Click Here
                                </Button>
                            </div>
                            <div className="pt-8">
                                <h3 className="text-md leading-6 font-extrabold text-rails-dark-blue">Logout</h3>
                            </div>
                            <div className="pt-1.5">
                                <div>
                                    <Button type="button" variant="destructive" size="sm" className="mt-2" onClick={handleLogOut}>
                                        Logout
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <ResetPassword open={showResetPassword} setOpen={setShowResetPassword} />
        </div>
    );
};

export default AccountSettings;
