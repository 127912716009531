import type { Column } from '@tanstack/table-core';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DateFilterProps<TData, TValue> {
    column: Column<TData, TValue>;
    title: string;
}

export function DataTableDateFilter<TData, TValue>({
    column,
    title
}: DateFilterProps<TData, TValue>) {
    const [date, setDate] = useState<{
        from?: Date;
        to?: Date;
    }>({});

    useEffect(() => {
        column.columnDef.filterFn = (row, columnId, filterValue) => {
            if (!filterValue?.from) return true;
            
            const rawValue = row.getValue(columnId);
            if (!rawValue) return true;
            
            const rowDate = new Date(rawValue);
            const from = new Date(filterValue.from);
            const to = filterValue.to ? new Date(filterValue.to) : from;
            
            from.setHours(0, 0, 0, 0);
            to.setHours(23, 59, 59, 999);
            
            return rowDate >= from && rowDate <= to;
        };
    }, [column]);

    useEffect(() => {
        if (date.from || date.to) {
            column.setFilterValue({
                from: date.from?.toISOString(),
                to: date.to?.toISOString()
            });
        } else {
            column.setFilterValue(undefined);
        }
    }, [date, column]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button 
                    variant="outline" 
                    size="sm"
                    className={cn(
                        "h-8 border-dashed",
                        date.from && "border-solid bg-muted"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        title
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    mode="range"
                    defaultMonth={date?.from}
                    selected={{
                        from: date?.from,
                        to: date?.to,
                    }}
                    onSelect={(selectedDate) => {
                        setDate(selectedDate ?? {});
                    }}
                    numberOfMonths={2}
                />
                <div className="p-3 border-t border-border">
                    <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                            setDate({});
                        }}
                    >
                        Clear Filter
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
}