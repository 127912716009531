/* eslint-disable no-nested-ternary */
import { filterSymbolsHumanReadable } from '../consts/vars';

const transformRuleConditionOptions = (ruleConditions) => {
    const conditionOptions = ruleConditions.map((data) => ({ label: data.name, value: data.col }));
    const conditionMetadataArr = ruleConditions.map((data) => {
        const allowedFilterOptions = data.allowed_filters.map((filter) => ({ label: filterSymbolsHumanReadable[filter], value: filter }));
        let allowedValueOptions;
        if (data.allowed_values) {
            allowedValueOptions = Object.keys(data.allowed_values).map((val) => ({ label: data.allowed_values[val], value: val }));
        }

        return { [data.col]: { filterOptions: allowedFilterOptions, valueOptions: allowedValueOptions, displayName: data.name } };
    });

    // convert array of objs into obj
    const conditionMetadata = Object.assign({}, ...conditionMetadataArr);

    return { conditionOptions, conditionMetadata };
};

const transformPricingCategories = (pricingCategories: any[], ruleData: any[]) => {
    // Handle case where pricingCategories is not an array
    if (!Array.isArray(pricingCategories)) {
        return [];
    }

    // Iterate over each pricing category
    return pricingCategories.map((category) => {
        const matchedRules = ruleData.filter((rule) => rule.charge_type_id === category.id);

        // Create a new category object with the transformed rules
        return {
            ...category,
            // Add the new rules field with the list of transformed rules
            rules: matchedRules,
        };
    });
};

export { transformRuleConditionOptions, transformPricingCategories };
