import { GlobeAltIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';

import { deleteInvoiceChargeTypesChargeTypeIdDelete } from '@/client/services.gen';
import PricingCategoryEdit from './pricingCategoryEdit.tsx';

const PricingCategoryView = ({
    openCategory,
    setOpenCategory,
    pricingCategory,
    setPricingCategory,
    chargeLevels,
}: {
    openCategory: boolean;
    setOpenCategory: (open: boolean) => void;
    pricingCategory: {
        id: string;
        name: string;
        description: string;
        charge_on_invoice: string;
        required: boolean;
        charge_level: string;
    };
    setPricingCategory: (category: any) => void;
    chargeLevels: Array<{ value: string; label: string }>;
}) => {
    const onDelete = (pricingCategoryId: string) => {
        deleteInvoiceChargeTypesChargeTypeIdDelete({ path: { chargeTypeId: pricingCategoryId } }).then(() => {
            setOpenCategory(false);
        });
    };

    return (
        pricingCategory && (
            <Dialog open={openCategory} onOpenChange={setOpenCategory}>
                <DialogContent className="sm:max-w-[600px]">
                    <DialogHeader className="pb-36 overflow-y-auto h-full">
                        <DialogTitle className="text-3xl">{pricingCategory && `${pricingCategory.name}`}</DialogTitle>
                        <DialogDescription className="text-md">{pricingCategory.description}</DialogDescription>

                        <div className="pt-4">
                            <div>Mentioned on customer invoices as </div>
                            <div className="italic font-bold">{pricingCategory.charge_on_invoice}</div>
                        </div>

                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <GlobeAltIcon className="h-6 w-6 mr-2" />
                                Required
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={pricingCategory.required} disabled />
                            </div>
                        </div>

                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <QueueListIcon className="h-6 w-6 mr-2" />
                                Charge Application Level
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <Input
                                    className="bg-white w-72 mt-2"
                                    type="text"
                                    placeholder={chargeLevels.filter((charge) => charge.value === pricingCategory.charge_level)[0].label}
                                    disabled
                                />
                            </div>
                        </div>
                    </DialogHeader>
                    <DialogFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {pricingCategory.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the charge type.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(pricingCategory.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <PricingCategoryEdit pricingCategoryValues={pricingCategory} setPricingCategory={setPricingCategory} chargeLevels={chargeLevels} />
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        )
    );
};

export default PricingCategoryView;
