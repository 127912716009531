import { PlusCircleIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import Select from 'react-select';

import { Input } from '@/components/ui/input';
import { Form, FormField, FormItem, FormControl, FormMessage, FormDescription, FormLabel } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Select as RegSelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/common/select';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/common/card';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/common/tooltip';
import LoadingSpinner from '@/components/ui/loading-spinner';
import { getInvoiceCustomersGet, getInvoiceChargeTypesGet } from '@/client/services.gen';
import { type InvoiceCustomer } from '@/client/types.gen';
import { TierConditionCol, PricingMethodVariableCol, PricingMethodCol, PricingMethodMarginCol, filterSymbolOptions } from '@/consts/vars';

interface RuleCondition {
    col: string;
    name: string;
    allowed_filters: Array<any>;
    allowed_values: Record<string, any> | null;
}

const PricingRuleDetailsTierCardCreateForm = ({
    formProps,
    tierIdx,
    removeTierDetail,
    invoiceUiOptions,
    cumulativeTiers,
}: {
    formProps: any;
    tierIdx: number;
    removeTierDetail: () => void;
    invoiceUiOptions: any;
    cumulativeTiers: boolean;
}) => {
    // Manage pricing choices for the specific tier
    const {
        fields: pricingChoicesArray,
        remove: removePricingChoice,
        append: appendPricingChoice,
    } = useFieldArray({
        control: formProps.control,
        name: `tiers[${tierIdx}].pricing_choices`,
    });

    const pricingChoiceFilter = [
        {
            label: 'maximum',
            value: 'MAX',
        },
        {
            label: 'minimum',
            value: 'MIN',
        },
        {
            label: 'sum',
            value: 'SUM',
        },
    ];

    // Initialize state for selected methods and columns
    const [selectedMethods, setSelectedMethods] = useState(pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].method`) || ''));

    const [selectedPricingCols, setSelectedPricingCols] = useState(
        pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].col`) || ''),
    );

    // Update the state when pricingChoicesArray changes
    useEffect(() => {
        const updatedMethods = pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].method`) || '');
        setSelectedMethods(updatedMethods);

        const updatedCols = pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].col`) || '');
        setSelectedPricingCols(updatedCols);
    }, [pricingChoicesArray, formProps.getValues, tierIdx]);

    // Function to handle method change
    const handleMethodChange = (e, index) => {
        const updatedMethods = [...selectedMethods];
        updatedMethods[index] = e; // Update the method at the specified index
        setSelectedMethods(updatedMethods); // Update the state
        formProps.setValue(`tiers[${tierIdx}].pricing_choices[${index}].method`, e); // Update form's state
    };

    // Function to handle column change
    const handleColChange = (e, index) => {
        const updatedCols = [...selectedPricingCols];
        updatedCols[index] = e; // Update the column at the specified index
        setSelectedPricingCols(updatedCols); // Update the state
        formProps.setValue(`tiers[${tierIdx}].pricing_choices[${index}].col`, e); // Update form's state
    };

    useEffect(() => {
        if (pricingChoicesArray.length === 0) {
            appendPricingChoice({
                method: '',
                value: '',
                col: '',
                filter: '',
            });
        }
    }, [appendPricingChoice, pricingChoicesArray.length]);

    return (
        <Card className="bg-gray-100 border-transparent shadow-none">
            <CardHeader>
                <CardTitle>
                    <div className="flow-root">
                        <div className="float-left text-gray-700">Tier {tierIdx + 1}</div>
                        <div className="float-right">
                            <XCircleIcon onClick={() => removeTierDetail(tierIdx)} className="h-6 w-6" />
                        </div>
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex items-center text-base space-x-2">
                    <div className="w-full">
                        <div className="grid grid-cols-3 gap-4">
                            <FormField
                                control={formProps.control}
                                name={`tiers[${tierIdx}].tier_conditions.rangeCol`}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <RegSelect {...field} onValueChange={field.onChange} value={field.value}>
                                                <SelectTrigger className="bg-white w-full">
                                                    <SelectValue placeholder="Select Column" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {invoiceUiOptions?.tierConditionOptions?.map((col) => (
                                                            <SelectItem key={col.value} value={col.value}>
                                                                {col.label}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </RegSelect>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={formProps.control}
                                name={`tiers[${tierIdx}].tier_conditions.rangeStart`}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input 
                                                type="number" 
                                                className="bg-white" 
                                                placeholder="Start" 
                                                {...field}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={formProps.control}
                                name={`tiers[${tierIdx}].tier_conditions.rangeEnd`}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input 
                                                type="number" 
                                                className="bg-white" 
                                                placeholder="End" 
                                                {...field}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        {pricingChoicesArray.length > 1 && (
                            <div className="w-48 mt-4">
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices_filter`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <RegSelect {...field} onValueChange={(value) => field.onChange(value)} defaultValue={field.value} value={field.value}>
                                                <SelectTrigger className="bg-white w-full">
                                                    <SelectValue placeholder="Select Filter" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {pricingChoiceFilter.map((filter) => (
                                                            <SelectItem key={filter.value} value={filter.value}>
                                                                {filter.label}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </RegSelect>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        )}

                        {pricingChoicesArray.map((pricingChoice, pricingChoiceIdx) => (
                            <div key={pricingChoice.id} className="mt-4 flex items-center space-x-4">
                                {/* Select for method */}
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].method`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <RegSelect {...field} onValueChange={(e) => handleMethodChange(e, pricingChoiceIdx)} value={field.value}>
                                                    <SelectTrigger className="bg-white w-full">
                                                        <SelectValue placeholder="Select Method" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            {invoiceUiOptions?.pricingMethodOptions?.map((col) => (
                                                                <SelectItem key={col.value} value={col.value}>
                                                                    {col.label}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </RegSelect>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Select for column if method is selected */}
                                {selectedMethods[pricingChoiceIdx] && (
                                    <div>
                                        <FormField
                                            control={formProps.control}
                                            name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].col`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <RegSelect {...field} onValueChange={(e) => handleColChange(e, pricingChoiceIdx)} value={field.value}>
                                                            <SelectTrigger className="bg-white w-full">
                                                                <SelectValue />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    {selectedMethods[pricingChoiceIdx].includes('margin') &&
                                                                        invoiceUiOptions?.pricingMethodMarginOptions.map((col) => (
                                                                            <SelectItem key={col.value} value={col.value}>
                                                                                {col.label}
                                                                            </SelectItem>
                                                                        ))}
                                                                    {selectedMethods[pricingChoiceIdx].includes('variable') &&
                                                                        invoiceUiOptions?.pricingMethodVariableOptions.map((col) => (
                                                                            <SelectItem key={col.value} value={col.value}>
                                                                                {col.label}
                                                                            </SelectItem>
                                                                        ))}
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </RegSelect>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                )}

                                {/* Input for value */}
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].value`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    className="w-16 text-center bg-white"
                                                    data-type={selectedMethods[pricingChoiceIdx]?.includes('percent') ? 'percent' : 'dollar'}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Render button to remove pricing choice */}
                                {pricingChoiceIdx > 0 && (
                                    <div>
                                        <XCircleIcon onClick={() => removePricingChoice(pricingChoiceIdx)} className="h-6 w-6" />
                                    </div>
                                )}
                            </div>
                        ))}

                        {!cumulativeTiers && (
                            <div className="flex justify-end mt-4">
                                <Button
                                    type="button"
                                    className="bg-primary text-white"
                                    variant="outline"
                                    onClick={() => {
                                        appendPricingChoice({
                                            method: '',
                                            value: '',
                                            col: '',
                                            filter: '',
                                        });
                                    }}
                                >
                                    <PlusCircleIcon className="h-4 w-4 mr-2" />
                                    Add Pricing Choice
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

interface PricingRuleFormProps {
    formProps: any;
    ruleConditions: Array<RuleCondition>;
    pricingRule?: any;
    onSubmit: (values: any) => Promise<void>;
    isLoading: boolean;
}

const PricingRuleForm = ({ formProps, ruleConditions, pricingRule, onSubmit, isLoading }: PricingRuleFormProps) => {
    const [isLoadingData, setIsLoadingData] = useState(true); // Renamed to avoid conflict
    const [activeInvoiceCustomers, setActiveInvoiceCustomers] = useState<Array<any>>([]);
    const [invoiceTypes, setInvoiceTypes] = useState<Array<any>>([]);
    const [invoiceUiOptions, setInvoiceUiOptions] = useState<Record<string, any>>({});
    const [selectedPricingType, setSelectedPricingType] = useState(formProps.formState?.defaultValues?.charge_type);
    const [pricingTypes, setPricingTypes] = useState<Array<any>>([]);
    const [tiersAreCumulative, setTiersAreCumulative] = useState(formProps.formState?.defaultValues?.tiers_are_cumulative ?? false);
    const [tierError, setTierError] = useState<Array<any>>([]);

    const { control, setValue } = formProps;

    const {
        fields: conditionsArray,
        remove: removeCondition,
        append: appendCondition,
    } = useFieldArray({
        control,
        name: 'rule_conditions',
    });

    const {
        fields: tiersArray,
        remove: removeTier,
        append: appendTier,
    } = useFieldArray({
        control,
        name: 'tiers',
    });

    useEffect(() => {
        const formErrors = formProps.formState?.errors;
        const tierErrors = formErrors?.tiers;
        setTierError(tierErrors || []);
    }, [formProps.formState?.errors]);

    useEffect(() => {
        Promise.all([getInvoiceCustomersGet(), getInvoiceChargeTypesGet()])
            .then((res) => {
                const customerList =
                    res[0]?.data
                        ?.filter((customer: InvoiceCustomer) => customer.active === true)
                        ?.map((item: InvoiceCustomer) => ({
                            label: item.name,
                            value: item.id || '',
                        })) || [];

                const invoiceTypesData = res[1]?.data || [];

                // Only proceed if we have valid invoice types data
                if (!invoiceTypesData || invoiceTypesData.length === 0) {
                    console.error('No invoice types data received');
                    return;
                }

                const pricingTypesData = invoiceTypesData
                    .filter((obj: any) => obj?.name !== 'package_rate' && !obj?.name?.toLowerCase()?.includes('special'))
                    .map((obj: any) => ({ label: obj.name, value: obj.id }));

                // Only set loading to false if we have valid pricing types
                if (!pricingTypesData || pricingTypesData.length === 0) {
                    console.error('No pricing types data after filtering');
                    return;
                }

                setActiveInvoiceCustomers(customerList);
                setInvoiceTypes(invoiceTypesData);
                setPricingTypes(pricingTypesData);

                const mapOptionsToLabelValue = (obj: Record<string, string>) =>
                    Object.entries(obj).map(([value, label]) => ({
                        label,
                        value,
                    }));

                const uiOptions = {
                    tierConditionOptions: mapOptionsToLabelValue(TierConditionCol),
                    pricingMethodOptions: mapOptionsToLabelValue(PricingMethodCol),
                    pricingMethodMarginOptions: mapOptionsToLabelValue(PricingMethodMarginCol),
                    pricingMethodVariableOptions: mapOptionsToLabelValue(PricingMethodVariableCol),
                };

                // Verify all required UI options are present
                if (
                    !uiOptions.tierConditionOptions?.length ||
                    !uiOptions.pricingMethodOptions?.length ||
                    !uiOptions.pricingMethodMarginOptions?.length ||
                    !uiOptions.pricingMethodVariableOptions?.length
                ) {
                    console.error('Missing required UI options');
                    return;
                }

                setInvoiceUiOptions(uiOptions);

                // Update this line to use the renamed state
                setIsLoadingData(false);
            })
            .catch((error) => {
                console.error('Error loading form data:', error);
                setIsLoadingData(false); // Make sure to set loading to false on error
            });
    }, [pricingRule]);

    // Update this useEffect to use the renamed state
    useEffect(() => {
        if (!isLoadingData && (!pricingTypes || pricingTypes.length === 0)) {
            console.warn('Form rendered without pricing types data');
        }
    }, [isLoadingData, pricingTypes]);

    useEffect(() => {
        if (selectedPricingType && invoiceTypes?.length > 0) {
            if (tiersArray.length === 0) {
                appendTier({
                    tier_conditions: {
                        rangeCol: '',
                        rangeStart: '',
                        rangeEnd: '',
                    },
                    pricing_choices: [],
                });
            }
        }
    }, [selectedPricingType, invoiceTypes, appendTier, tiersArray.length]);

    // Move the helper function inside the component to access ruleConditions prop
    const getFilterOptions = (selectedCol: string | undefined) => {
        if (!selectedCol) return filterSymbolOptions;

        const condition = ruleConditions.find((condition) => condition.col === selectedCol);
        const allowedFilters = condition?.allowed_filters;

        if (!allowedFilters) return filterSymbolOptions;

        // Convert string array to array of objects with label/value pairs
        return allowedFilters.map((filter) => ({
            label: filter,
            value: filter,
        }));
    };

    return (
        <div className="pl-2 pr-8 pb-8">
            <LoadingSpinner loading={isLoadingData} />
            {!isLoadingData && (
                <Form {...formProps} className="space-y-8 pl-4 pr-4" onSubmit={formProps.handleSubmit(onSubmit)}>
                    <FormField
                        control={formProps.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem className="mt-4">
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter pricing rule name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={formProps.control}
                        name="default"
                        render={({ field }) => (
                            <div className="grid grid-rows-2">
                                <FormLabel className="mt-4">Apply to All Customers?</FormLabel>
                                <FormItem className="mt-1">
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            </div>
                        )}
                    />

                    {!formProps.getValues('default') && (
                        <FormField
                            control={formProps.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem className="mt-4">
                                    <FormLabel>Customers</FormLabel>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={[
                                                {
                                                    label: "Select All Customers",
                                                    value: "select-all"
                                                },
                                                ...activeInvoiceCustomers
                                            ]}
                                            value={field.value}
                                            onChange={(newValue: any) => {
                                                if (newValue?.some((option: any) => option.value === 'select-all')) {
                                                    field.onChange(activeInvoiceCustomers);
                                                } else {
                                                    field.onChange(newValue || []);
                                                }
                                            }}
                                            placeholder="Customer Name (optional)"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )}

                    <FormField
                        control={formProps.control}
                        name="charge_type"
                        render={({ field: { onChange, value, ...field } }) => (
                            <FormItem className="mt-4">
                                <FormLabel>Charge Type</FormLabel>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={pricingTypes}
                                        value={value ? pricingTypes?.find((option) => option.value === value?.value) : null}
                                        onChange={(e) => {
                                            setSelectedPricingType(e);
                                            onChange(e);
                                        }}
                                        closeMenuOnSelect
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    {/* Pricing Rule Conditions Begins */}
                    <FormItem className="mt-4">
                        <FormLabel>Conditions (optional)</FormLabel>
                        {conditionsArray.map((cond, condIdx) => (
                            <div key={cond.id} className="grid grid-cols-12 gap-2">
                                <div className="text-gray-500">
                                    <div className="mt-4 ml-2">
                                        <XCircleIcon onClick={() => removeCondition(condIdx)} className="h-6 w-6 cursor-pointer" />
                                    </div>
                                </div>
                                <div className="col-span-11">
                                    <div className="mt-2 flex space-x-2">
                                        <FormItem className="w-1/3">
                                            <FormField
                                                control={formProps.control}
                                                name={`rule_conditions.${condIdx}.col`}
                                                render={({ field: { onChange, value, ...field } }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                menuPlacement="bottom"
                                                                options={ruleConditions.map((condition) => ({
                                                                    label: condition.name,
                                                                    value: condition.col,
                                                                }))}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    setValue(`rule_conditions.${condIdx}.filter`, null);
                                                                    setValue(`rule_conditions.${condIdx}.value`, null);
                                                                }}
                                                                closeMenuOnSelect
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </FormItem>
                                        <FormItem className="w-1/3">
                                            <FormField
                                                control={formProps.control}
                                                name={`rule_conditions.${condIdx}.filter`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                options={getFilterOptions(formProps.getValues(`rule_conditions.${condIdx}.col`)?.value)}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                closeMenuOnSelect
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </FormItem>
                                        <FormItem className="w-1/3">
                                            <FormField
                                                control={formProps.control}
                                                name={`rule_conditions.${condIdx}.value`}
                                                render={({ field }) => {
                                                    const selectedCol = formProps.getValues(`rule_conditions.${condIdx}.col`)?.value;
                                                    const condition = ruleConditions.find((c) => c.col === selectedCol);
                                                    const hasAllowedValues = condition?.allowed_values;
                                                    const allowsMultiple = ['in', 'not in'].includes(formProps.getValues(`rule_conditions.${condIdx}.filter`)?.value);

                                                    if (hasAllowedValues) {
                                                        const options = Object.entries(condition.allowed_values).map(([value, label]) => ({
                                                            value,
                                                            label,
                                                        }));

                                                        return (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <Select
                                                                        {...field}
                                                                        options={options}
                                                                        value={field.value}
                                                                        onChange={field.onChange}
                                                                        isMulti={allowsMultiple}
                                                                        closeMenuOnSelect={!allowsMultiple}
                                                                    />
                                                                </FormControl>
                                                                <FormMessage />
                                                            </FormItem>
                                                        );
                                                    }

                                                    return (
                                                        <FormItem>
                                                            <FormControl>
                                                                <Input className="bg-white" type="text" placeholder="Enter value" {...field} />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    );
                                                }}
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="mt-4 text-gray-500">
                            <Button
                                type="button"
                                className="h-8"
                                variant="outline"
                                onClick={() => {
                                    appendCondition({
                                        col: '',
                                        filter: '',
                                        value: '',
                                    });
                                }}
                            >
                                <PlusCircleIcon className="h-4 w-4 mr-2" />
                                Add condition
                            </Button>
                        </div>
                    </FormItem>
                    {/* Pricing Rule Conditions Ends */}

                    <FormDescription className="mt-12">Pricing Definition</FormDescription>
                    <div className="grid grid-rows-2">
                        <div className="flex items-center grid grid-cols-5 pt-1 pb-2">
                            <div className="flex text-md col-span-4 whitespace-nowrap text-wrap">
                                Should charges from each tier be summed up?
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <InformationCircleIcon className="h-4 w-4 ml-2" />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            If chosen, each billable unit incurs a separate charge based on the tier it meets conditions for, and the total charge for the shipment
                                            is the sum of these individual unit charges. If not, the charge for the shipment is determined by the specific tier whose conditions are
                                            met.
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                            <div className="flex col-span-1 justify-end">
                                <FormField
                                    control={formProps.control}
                                    name="tiers_are_cumulative"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Switch
                                                    onCheckedChange={(checked) => {
                                                        setTiersAreCumulative(checked);
                                                        field.onChange(checked);
                                                    }}
                                                    checked={field.value}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    {tiersArray.map((tierDetail, tierIdx) => (
                        <div key={tierDetail.id} className="mt-2">
                            <PricingRuleDetailsTierCardCreateForm
                                formProps={formProps}
                                tierIdx={tierIdx}
                                removeTierDetail={() => removeTier(tierIdx)}
                                invoiceUiOptions={invoiceUiOptions}
                                cumulativeTiers={tiersAreCumulative}
                            />
                        </div>
                    ))}

                    <div className="mt-4 mb-12">
                        <Button
                            type="button"
                            className="h-8"
                            variant="outline"
                            onClick={() => {
                                appendTier({
                                    tier_conditions: {
                                        rangeCol: '',
                                        rangeStart: '',
                                        rangeEnd: '',
                                    },
                                    pricing_choices: [],
                                });
                            }}
                        >
                            <PlusCircleIcon className="h-4 w-4 mr-2" />
                            Add Tier
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default PricingRuleForm;
