/* eslint-disable no-nested-ternary */
import Select from 'react-select';

import {
    Form,
    FormField,
    FormItem,
    FormControl,
    FormMessage,
} from '@/components/ui/form.tsx';
import CronSchedule from '@/components/common/cronSchedule.jsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import {
    DateType,
    DeliveryMethod,
    type ChargeType,
} from '@/client/types.gen.ts';

const ConfigForm = ({
    formProps,
    setInvoiceCronString,
    setChargeInvoiceCronString,
    cronValidationRef,
    chargeTypes,
}: {
    formProps: any;
    setInvoiceCronString: (cronString: string) => void;
    setChargeInvoiceCronString: (cronString: string) => void;
    cronValidationRef: React.MutableRefObject<boolean>;
    chargeTypes: ChargeType[];
}) => (
    <Form {...formProps}>
        <form className="space-y-2">
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Delivery Cadence
                </div>
                <div className="flex text-sm">
                    Define invoicing delivery cadence. You can choose to deliver
                    invoice-level charges on a separate cadence below.
                </div>
                <CronSchedule
                    className="mt-2"
                    setCronString={setInvoiceCronString}
                    cronValidationRef={cronValidationRef}
                    initialCronString={formProps.getValues().invoice_cadence}
                    allowSetHourly={false}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Billable Activity Qualifier
                </div>
                <div className="flex text-sm">
                    Please select the date field used for qualifying billable
                    activity
                </div>
                <FormField
                    control={formProps.control}
                    name="package_billing_date_type"
                    render={({ field: { onChange, value, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    value={
                                        value
                                            ? {
                                                  value: value,
                                                  label: value,
                                              }
                                            : null
                                    }
                                    onChange={(option) =>
                                        onChange(option?.value)
                                    }
                                    options={Object.values(DateType).map(
                                        (type) => ({
                                            value: type,
                                            label: type,
                                        }),
                                    )}
                                    placeholder="Date Type selection"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Delivery Method
                </div>
                <FormField
                    control={formProps.control}
                    name="delivery_method"
                    render={({ field: { onChange, value, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    value={
                                        value
                                            ? {
                                                  value: value,
                                                  label: value,
                                              }
                                            : null
                                    }
                                    onChange={(option) =>
                                        onChange(option?.value)
                                    }
                                    options={Object.values(DeliveryMethod).map(
                                        (type) => ({
                                            value: type,
                                            label: type,
                                        }),
                                    )}
                                    placeholder="Choose delivery method for customer invoices"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Invoice Level Charges Delivery Cadence
                </div>
                <div className="flex text-sm">
                    Invoice-level charges can be delivered at a different
                    cadence from the rest of your invoice in Rails.
                </div>
                {!formProps.watch('same_cadence_as_invoice') && (
                    <CronSchedule
                        className="mt-2"
                        setCronString={setChargeInvoiceCronString}
                        cronValidationRef={cronValidationRef}
                        initialCronString={
                            formProps.getValues().charge_invoice_cadence
                        }
                        allowSetHourly={false}
                    />
                )}
                <FormField
                    control={formProps.control}
                    name="same_cadence_as_invoice"
                    render={({ field }) => (
                        <FormItem>
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    defaultChecked={false}
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                                <label
                                    htmlFor="terms"
                                    className="capitalize text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Same as Invoice Delivery Cadence
                                </label>
                            </div>
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Add Carrier Adjustments
                </div>
                <div className="flex text-sm">
                    Enable automatic carrier adjustments per shipment on the
                    invoice
                </div>
                <FormField
                    control={formProps.control}
                    name="add_carrier_adjustments"
                    render={({ field }) => (
                        <FormItem>
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                                <label className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    Add Carrier Adjustments
                                </label>
                            </div>
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">
                    Shipping Rules Charge Type
                </div>
                <div className="flex text-sm">
                    Select the charge type to use for shipping rules
                </div>
                <FormField
                    control={formProps.control}
                    name="shipping_charge_type_id"
                    render={({ field: { onChange, value, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    value={
                                        value
                                            ? {
                                                  value: value,
                                                  label:
                                                      chargeTypes.find(
                                                          (ct) =>
                                                              ct.id === value,
                                                      )?.name || '',
                                              }
                                            : null
                                    }
                                    onChange={(option) =>
                                        onChange(option?.value)
                                    }
                                    options={[
                                        { value: null, label: 'None' },
                                        ...chargeTypes.map((ct) => ({
                                            value: ct.id,
                                            label: ct.name,
                                        })),
                                    ]}
                                    placeholder="Select a charge type"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </form>
    </Form>
);

export default ConfigForm;
