import type { InvoiceCustomerPublic } from '@/client';
import { useEffect, useState } from 'react';
import { Switch } from '../ui/switch';
import { useNavigate } from 'react-router-dom';
import type { Row } from '@tanstack/react-table';
import { DataTable } from '../ui/datatable/data-table';
import { Button } from '../ui/button';

interface customerPortalUsersProps {
    users: any[];
    isEnabled: boolean;
}

const CustomerPortalUsers = ({
    users,
    isEnabled,
}: customerPortalUsersProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        {
            accessorKey: 'email',
            header: 'Email',
            cell: ({ row }: { row: Row<any> }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px]">{row.getValue('email')}</span>
                </div>
            ),
        },
        {
            accessorKey: 'active',
            header: 'Active',
            cell: ({ row }: { row: Row<any> }) => (
                <div className="w-[200px]">
                    {isEnabled ? (
                        <Switch checked={row.getValue('active')} />
                    ) : (
                        <Switch checked={false} disabled />
                    )}
                </div>
            ),
        },
    ];

    return (
        <DataTable
            loading={isLoading}
            data={users}
            columns={columns}
            showPagination={false}
            showToolBar={false}
            tableName="brand users"
        />
    );
};

export interface CustomerPortalAdminProps {
    invoiceCustomer: InvoiceCustomerPublic;
}

const CustomerPortalAdmin = ({ invoiceCustomer }: CustomerPortalAdminProps) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [brandUsers, setBrandUsers] = useState([]);

    const navigate = useNavigate();

    // TODO: Implement this
    const handleAddUser = () => {
        return null;
    };

    // TODO: Implement this
    useEffect(() => {
        const fetchBrandUsers = async () => {
            setBrandUsers([]);
        };
        fetchBrandUsers();
    }, []);

    return (
        <div className="p-1">
            <h1 className="font-medium">Enable Brand Portal</h1>
            <div className="flex items-center gap-4 mt-2">
                <Switch checked={isEnabled} onCheckedChange={setIsEnabled} />
                <div className="flex items-center gap-4">
                    <Button
                        variant="link"
                        onClick={() => navigate('/brand-portal')}
                        disabled={!isEnabled}
                    >
                        {`View ${invoiceCustomer.name} Portal`}
                    </Button>
                    <div className="h-4 w-px bg-gray-200" />
                    <Button
                        variant="link"
                        onClick={handleAddUser}
                        disabled={!isEnabled}
                    >
                        Add User
                    </Button>
                </div>
            </div>
            <div className="gap-4 mt-2">
                {
                    <CustomerPortalUsers
                        users={brandUsers}
                        isEnabled={isEnabled}
                    />
                }
            </div>
        </div>
    );
};

export default CustomerPortalAdmin;
