import type { Column } from '@tanstack/table-core';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Switch } from '@/components/ui/switch';
import { useState } from 'react';

interface BooleanFilterProps<TData, TValue> {
    column: Column<TData, TValue>;
    title: string;
}

export function DataTableBooleanFilter<TData, TValue>({
    column,
    title
}: BooleanFilterProps<TData, TValue>) {
    const [value, setValue] = useState<boolean | undefined>(undefined);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button 
                    variant="outline" 
                    size="sm" 
                    className="h-8 border-dashed"
                    {...(value !== undefined && {
                        className: "h-8 border-solid bg-muted"
                    })}
                >
                    {title}
                    {value !== undefined && `: ${value ? 'Yes' : 'No'}`}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-2">
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <Switch
                            checked={value ?? false}
                            onCheckedChange={(checked) => {
                                setValue(checked);
                                column.setFilterValue(checked);
                            }}
                        />
                        <span>Show {value ? 'Active' : 'Inactive'}</span>
                    </div>
                    <Button
                        size="sm"
                        variant="outline"
                        className="mt-2"
                        onClick={() => {
                            setValue(undefined);
                            column.setFilterValue(undefined);
                        }}
                    >
                        Clear Filter
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
}