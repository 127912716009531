import { useState, useEffect } from 'react';
import { useToast } from '@/components/common/use-toast.tsx';
import type { UOM } from '@/client/types.gen.ts';
import { CircleCheck, CirclePlus, X, XCircle } from 'lucide-react';
import { createV2ProductsProfilesPost, readManyV2ProductsProfilesGet, deleteV2ProductsProfilesProfileIdDelete } from '@/client/services.gen.ts';
import { Input } from '@/components/ui/input.tsx';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card.tsx';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button.tsx';
import LoadingSpinner from '@/components/ui/loading-spinner.tsx';

const ProductProfileConfigDialog = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productProfileConfigData, setProductProfileConfigData] = useState<Array<UOM>>([]);
    const [inputIsVisible, setInputIsVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { toast } = useToast();

    const fetchProductProfileConfig = async () => {
        setIsLoading(true);
        try {
            const { data, error } = await readManyV2ProductsProfilesGet();
            if (error) {
                console.error(error);
                return;
            }
            if (data) {
                setProductProfileConfigData(data);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProductProfileConfig();
    }, []);

    const addProductProfile = async () => {
        if (inputValue.length === 0) {
            toast({
                variant: 'destructive',
                title: 'Product Profile name cannot be empty.',
            });
            return;
        }

        const options = { query: { name: inputValue } };
        try {
            setIsLoading(true);
            await createV2ProductsProfilesPost(options);
            await fetchProductProfileConfig();
            toast({
                variant: 'default',
                title: 'Product Profile added successfully.',
            });
            setInputIsVisible(false);
            setInputValue('');
        } catch (e) {
            toast({
                variant: 'destructive',
                title: 'Failed to add Product Profile.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteProductProfile = async (id: string | undefined) => {
        if (!id) {
            return;
        }
        const options = { path: { profile_id: id } };
        try {
            setIsLoading(true);
            await deleteV2ProductsProfilesProfileIdDelete(options);
            fetchProductProfileConfig();
            toast({
                variant: 'default',
                title: 'Product Profile deleted successfully.',
            });
        } catch (e) {
            toast({
                variant: 'destructive',
                title: 'Failed to delete Product Profile.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelInput = () => {
        setInputIsVisible(false);
        setInputValue('');
    };

    const handleShowInput = () => {
        setInputIsVisible(true);
    };

    return (
        <div>
            <div className="container mx-auto py-4">
                <Card className="w-full">
                    <CardHeader>
                        <CardTitle className="text-lg font-medium text-gray-900 flex items-center">
                            Product Profiles for Billing
                            {isLoading && <LoadingSpinner className="ml-2 h-4 w-4" loading={isLoading} />}
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex gap-1">
                            {isLoading ? (
                                <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                            ) : (
                                productProfileConfigData?.map((productProfile) => (
                                    <div className="flex items-center gap-x-1">
                                        <div className="p-3 bg-gray-200 text-gray-600 rounded-sm">{productProfile.name}</div>
                                        <Dialog>
                                            <DialogTrigger>
                                                <X className="cursor-pointer text-gray-500 hover:bg-red-600 hover:text-white" size={20} />
                                            </DialogTrigger>
                                            <DialogContent>
                                                <DialogHeader>
                                                    <DialogTitle>Delete {productProfile.name}</DialogTitle>
                                                    <DialogClose />
                                                </DialogHeader>
                                                <DialogDescription>
                                                    Are you sure you want to remove {productProfile.name} as a Product Profile? This action will delete all the rules set up for it.
                                                </DialogDescription>
                                                <DialogFooter>
                                                    <Button onClick={() => deleteProductProfile(productProfile?.id)}>Delete</Button>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                ))
                            )}
                            {inputIsVisible && (
                                <div className="flex gap-x-1 px-1 text-gray-600  rounded-sm">
                                    <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} type="text" placeholder="input ..." className="w-32 px-2" />
                                    <CircleCheck className="self-center cursor-pointer text-gray-500 hover:text-green-500" size={20} onClick={addProductProfile} />
                                    <XCircle className="self-center cursor-pointer text-gray-500 hover:text-red-500" size={20} onClick={handleCancelInput} />
                                </div>
                            )}
                            {!inputIsVisible && <CirclePlus className="self-center cursor-pointer text-gray-500 hover:text-green-500" size={20} onClick={handleShowInput} />}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default ProductProfileConfigDialog;
