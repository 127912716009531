import { Form, FormField, FormItem, FormControl, FormMessage } from '@/components/common/form';
import { Input } from '@/components/common/input';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/common/select';
import type { FC } from 'react';
import { type WMSCustomer, type ProductProfile } from '@/client/types.gen.js';

interface ProductSearchFormProps {
    formProps: any;
    _isLoading: boolean;
    _setIsLoading: boolean;
    customers: WMSCustomer[];
    profiles: ProductProfile[];
}

const ProductSearchForm: FC<ProductSearchFormProps> = ({ formProps, _isLoading, _setIsLoading, customers = [], profiles = [] }) => {
    return (
        <Form {...formProps}>
            <div className="grid grid-cols-2 gap-4">
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Product SKU</div>
                    <FormField
                        control={formProps.control}
                        name="skus"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input placeholder="Product SKU" type="text" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Customer</div>
                    <FormField
                        control={formProps.control}
                        name="customers"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select onValueChange={(value) => field.onChange([value])} value={field.value?.[0] || ''}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select Customer" />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[200px] overflow-y-auto">
                                            {customers?.map((customer) => (
                                                <SelectItem key={customer.id} value={customer.id}>
                                                    {customer.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Product Profile</div>
                    <FormField
                        control={formProps.control}
                        name="profile_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select onValueChange={(value) => field.onChange([value])} value={field.value?.[0] || ''}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select Profile" />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[200px] overflow-y-auto">
                                            {profiles?.map((profile) => (
                                                <SelectItem key={profile.id} value={profile.id}>
                                                    {profile.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </Form>
    );
};

export default ProductSearchForm;
