/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import { Plus, MoreHorizontal } from 'lucide-react';
import { DataTable } from '@/components/ui/datatable/data-table';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import PricingRuleView from './pricingRuleView.tsx';
import { transformPricingCategories } from '../../../utils/invoice.tsx';
import PricingCategoryView from '../pricingCategories/pricingCategoryView.tsx';
import PricingRuleCreate from './pricingRuleCreate.tsx';
import PricingCategoryCreate from '../pricingCategories/pricingCategoryCreate.tsx';
import {
    deleteInvoiceChargeTypesChargeTypeIdDelete,
    getRuleConditionOptionsInvoiceUiRuleConditionOptionsGet,
    getInvoiceCustomersGet,
    getInvoiceChargeTypesGet,
    getAllInvoiceRulesGet,
    deleteInvoiceRulesRuleIdDelete,
} from '@/client/services.gen';
import { ChargeLevelTypes } from '@/client/types.gen';
import {
    AlertDialog,
    AlertDialogTrigger,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
    AlertDialogAction,
} from '@/components/ui/alert-dialog';
import { Checkbox } from '@/components/ui/checkbox';

const PricingRules = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRule, setSelectedRule] = useState(null);
    const [pricingCategories, setPricingCategories] = useState([]);
    const [ruleConditionMetadata, setRuleConditionMetadata] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const chargeLevels = Object.entries(ChargeLevelTypes).map(
        ([key, value]) => ({
            label: key
                .split('_')
                .map(
                    (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase(),
                )
                .join(' '),
            value: value,
        }),
    );

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openPricingRuleForm, setOpenPricingRuleForm] = useState(false);
    const [openPricingCategoryForm, setOpenPricingCategoryForm] =
        useState(false);
    const [ruleChange, setRuleChange] = useState(false);
    const openCategoryView = (row: { original: { id: string | number } }) => {
        const selectedCategoryArr = pricingCategories.filter(
            (data: any) => data.id === row.original.id,
        );
        setSelectedCategory(selectedCategoryArr[0]);
        setOpenCategory(true);
    };
    const openRuleView = (row: { original: { id: string | number } }) => {
        const selectedRuleArr = tableData.filter(
            (data: any) => data.id === row.original.id,
        );
        setSelectedRule(selectedRuleArr[0]);
        setOpen(true);
    };

    const fetchRules = () => {
        setIsLoading(true);

        Promise.all([
            getAllInvoiceRulesGet(),
            getRuleConditionOptionsInvoiceUiRuleConditionOptionsGet(),
            getInvoiceCustomersGet(),
            getInvoiceChargeTypesGet(),
        ]).then((res) => {
            let filteredRules = Array.isArray(res[0]?.data)
                ? res[0].data.filter(
                      (rule) =>
                          rule.charge_type !== 'package_rate' &&
                          !rule.name.includes('attributing missing'),
                  )
                : [];
            const customerList = Array.isArray(res[2]?.data) 
                ? res[2].data.filter(customer => customer?.active)
                : [];
            const customerMap = {};
            customerList.forEach((customer) => {
                if (customer?.id && customer?.name) {
                    customerMap[customer.id] = customer.name;
                }
            });

            filteredRules = filteredRules.map((rule) => ({
                ...rule,
                customer_ids:
                    rule.customer_ids?.map((customerId) => ({
                        label: customerMap[customerId] || customerId,
                        value: customerId,
                    })) || [],
            }));

            let pricingCategoriesData = transformPricingCategories(
                res[3]?.data || [],
                filteredRules,
            );
            pricingCategoriesData = pricingCategoriesData.filter(
                (category) =>
                    category.name !== 'Product' &&
                    category.name !== 'package_rate' &&
                    !category.name.includes('Special rule'),
            );

            setTableData(filteredRules);
            setPricingCategories(pricingCategoriesData);
            setRuleConditionMetadata(res[1]?.data || []);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchRules();
    }, [ruleChange]);

    const pricingRuleColumns = [
        {
            accessorKey: 'name',
            header: 'Rule Name',
            cell: ({ row }: { row: any }) => (
                <div
                    className="w-[200px] font-medium hover:underline"
                    onClick={() => openRuleView(row)}
                >
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'customer_ids',
            header: 'Customers',
            cell: ({ row }: { row: any }) => {
                const customers = row.getValue('customer_ids');
                if (customers?.length > 0) {
                    const customerLabels = customers.map(
                        (customer) => customer.label,
                    );
                    const customerLabelsJoined = customerLabels.join(', ');
                    return (
                        <div className="customer-list">
                            {customerLabelsJoined}
                        </div>
                    );
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },

        {
            accessorKey: 'rule_conditions',
            header: 'Conditional Application',
            cell: ({ row }: { row: any }) => {
                const rules = row.getValue('rule_conditions');
                if (rules?.length > 0) {
                    return 'Yes';
                }
                return 'No';
            },
            enableSorting: true,
            enableHiding: false,
        },
    ];
    const pricingCategoryColumns = [
        {
            accessorKey: 'name',
            header: 'Charge Type',
            cell: ({ row }: { row: any }) => (
                <div
                    className="w-[200px] font-medium hover:underline"
                    onClick={() => openCategoryView(row)}
                >
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_on_invoice',
            header: 'Reported On Client Invoice As',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium">
                    {row.getValue('charge_on_invoice')}
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_level',
            header: 'Charge Application Level',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium">
                    {
                        chargeLevels.find(
                            (charge) =>
                                charge.value === row.getValue('charge_level'),
                        ).label
                    }
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'rules',
            header: 'Number of Rules',
            cell: ({ row }: { row: any }) => {
                const rules = row.getValue('rules');
                if (rules?.length > 0) {
                    return <div className="customer-list">{rules.length}</div>;
                }
                return '';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }: { row: any }) => {
                const rowRules = row.original?.rules || [];
                if (rowRules?.length === 0) {
                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem
                                    onClick={() => {
                                        deleteInvoiceChargeTypesChargeTypeIdDelete(
                                            {
                                                path: {
                                                    chargeTypeId:
                                                        row.original.id,
                                                },
                                            },
                                        ).then(() => {
                                            setOpenCategory(false);
                                            fetchRules();
                                        });
                                    }}
                                >
                                    Delete Charge Type
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
                return null;
            },
        },
    ];

    const TableActionButton = () => (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button className="p-4">
                    <Plus className="h-4 w-4 mr-2" />
                    New Charge
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem
                    onClick={() => {
                        setOpenPricingCategoryForm(true);
                    }}
                >
                    Charge Type
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() => {
                        setOpenPricingRuleForm(true);
                    }}
                >
                    Pricing Rule
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );

    const DataTableComponent = () => {
        const [selectedRules, setSelectedRules] = useState<any[]>([]);
        const [isDeleting, setIsDeleting] = useState(false);

        const DeleteButton = () =>
            selectedRules.length > 0 ? (
                <AlertDialog>
                    <AlertDialogTrigger asChild>
                        <Button variant="destructive" size="sm">
                            Delete ({selectedRules.length})
                        </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>
                                Delete {selectedRules.length} rules?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                                This cannot be undone.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                                onClick={async () => {
                                    setIsDeleting(true);
                                    try {
                                        await Promise.all(
                                            selectedRules.map((rule) =>
                                                deleteInvoiceRulesRuleIdDelete({
                                                    path: { ruleId: rule.id },
                                                }),
                                            ),
                                        );
                                        setSelectedRules([]);
                                        fetchRules();
                                    } finally {
                                        setIsDeleting(false);
                                    }
                                }}
                                disabled={isDeleting}
                            >
                                {isDeleting ? 'Deleting...' : 'Delete'}
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            ) : null;

        return (
            <DataTable
                loading={isLoading}
                data={pricingCategories}
                ActionButton={<TableActionButton />}
                columns={pricingCategoryColumns}
                collapsible
                collapsibleContent={({ row }) => {
                    if (row?.rules) {
                        return (
                            <div>
                                <div className="flex justify-end mb-2">
                                    <DeleteButton />
                                </div>
                                <DataTable
                                    loading={isLoading}
                                    data={row.rules}
                                    columns={[
                                        {
                                            id: 'select',
                                            cell: ({ row }) => (
                                                <Checkbox
                                                    checked={selectedRules.some(
                                                        (rule) =>
                                                            rule.id ===
                                                            row.original.id,
                                                    )}
                                                    onCheckedChange={(
                                                        checked,
                                                    ) => {
                                                        setSelectedRules(
                                                            (prev) =>
                                                                checked
                                                                    ? [
                                                                          ...prev,
                                                                          row.original,
                                                                      ]
                                                                    : prev.filter(
                                                                          (
                                                                              rule,
                                                                          ) =>
                                                                              rule.id !==
                                                                              row
                                                                                  .original
                                                                                  .id,
                                                                      ),
                                                        );
                                                    }}
                                                />
                                            ),
                                        },
                                        ...pricingRuleColumns,
                                    ]}
                                    showPagination={true}
                                    showToolBar={false}
                                />
                            </div>
                        );
                    }
                    return null;
                }}
            />
        );
    };

    const PricingRuleViewComponent = () => (
        <PricingRuleView
            open={open}
            setOpen={setOpen}
            pricingRule={selectedRule}
            setPricingRule={setSelectedRule}
            ruleConditionMetadata={ruleConditionMetadata}
            setRuleChange={setRuleChange}
            ruleChange={ruleChange}
        />
    );

    const PricingCategoryViewComponent = () => {
        if (openCategory) {
            return (
                <PricingCategoryView
                    openCategory={openCategory}
                    setOpenCategory={setOpenCategory}
                    pricingCategory={selectedCategory}
                    setPricingCategory={setSelectedCategory}
                    chargeLevels={chargeLevels}
                />
            );
        }
        return null;
    };

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">
                                Client Charges
                            </h1>
                            <h2 className="text-xs text-rails-dark-blue">
                                Create charges and manage rules used to
                                calculate invoice line items.
                            </h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && <DataTableComponent />}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {ruleConditionMetadata && <PricingRuleViewComponent />}
            <PricingCategoryViewComponent />
            {ruleConditionMetadata && (
                <PricingRuleCreate
                    isDialogOpen={openPricingRuleForm}
                    setIsDialogOpen={setOpenPricingRuleForm}
                    ruleConditions={ruleConditionMetadata}
                    setRuleChange={setRuleChange}
                    ruleChange={ruleChange}
                />
            )}
            <PricingCategoryCreate
                chargeLevels={chargeLevels}
                isDialogOpen={openPricingCategoryForm}
                setIsDialogOpen={setOpenPricingCategoryForm}
                setRuleChange={setRuleChange}
                ruleChange={ruleChange}
            />
        </div>
    );
};

export default PricingRules;
