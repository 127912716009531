import { useState, useEffect, useRef } from 'react';
import {
    diffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGet,
    missingWmsDataReconciliationMissingWmsDataGet,
    diffCarrierWmsChargesJsonReconciliationDiffCarrierWmsChargesJsonGet,
    missingWmsDataJsonReconciliationMissingWmsDataJsonGet,
    postConfigCarrierReportExportEmailPost,
    getCarriersCarrierBillsCarriersGet,
    missingCarrierDataJsonReconciliationMissingCarrierDataJsonGet,
} from '@/client/services.gen';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { DataTable } from '@/components/ui/datatable/data-table';
import {
    CarrierReportExportType,
    type MissingWMSData,
    type DiffCarrierWMSCharges,
} from '@/client/types.gen';
import { NumericFormat } from 'react-number-format';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogDescription,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Input } from '@/components/ui/input';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';

interface GroupedDiffCarrierWmsCharges {
    customerName: string;
    entries: DiffCarrierWMSCharges[];
    count: number;
    totalDifference: number;
}

interface GroupedDiffCarrierWmsChargesByCarrier {
    carrierName: string;
    entries: DiffCarrierWMSCharges[];
    count: number;
    totalDifference: number;
}

interface GroupedMissingWmsData {
    carrierName: string;
    entries: MissingWMSData[];
    count: number;
    totalCharges: number;
}

export default function CarrierExceptions() {
    const [diffCarrierWmsCharges, setDiffCarrierWmsCharges] = useState<
        GroupedDiffCarrierWmsCharges[] | null
    >(null);
    const [diffCarrierWmsChargesByCarrier, setDiffCarrierWmsChargesByCarrier] =
        useState<GroupedDiffCarrierWmsChargesByCarrier[] | null>(null);
    const [missingWmsData, setMissingWmsData] = useState<
        GroupedMissingWmsData[] | null
    >(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(
        subWeeks(new Date(), 1),
    );
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const reportOptions = Object.entries(CarrierReportExportType)
        .filter(([key]) => key !== 'CARRIER_CHARGE_DETAIL')
        .map(([_, value]) => ({
            value: value,
            label: value
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
        }));
    const [selectedReport, setSelectedReport] = useState(
        reportOptions[0].value,
    );
    const [emailRecipients, setEmailRecipients] = useState('');
    const [emailReportType, setEmailReportType] = useState(
        reportOptions[0].value,
    );
    const initialFetchDone = useRef(false);
    const [availableCarriers, setAvailableCarriers] = useState<
        { id: string; name: string }[]
    >([]);
    const [selectedDownloadCarrier, setSelectedDownloadCarrier] =
        useState<string>('ALL_CARRIERS');
    const handleEmailSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmittingEmail(true);
        try {
            await postConfigCarrierReportExportEmailPost({
                body: {
                    email: emailRecipients,
                    report_type: emailReportType,
                },
            });
            setSuccessMessage('Email schedule set successfully');
        } catch (error) {
            setError('Failed to schedule email report');
            console.error('Error scheduling email:', error);
        } finally {
            setIsSubmittingEmail(false);
        }
    };

    const fetchDiffCarrierWmsCharges = async () => {
        const calculateDifference = (item: DiffCarrierWMSCharges): number => {
            return (
                parseFloat(item.carrier_total ?? '0') -
                parseFloat(item.label_total)
            );
        };
        const response =
            await diffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGet({
                query: {},
            });
        const data = response.data!;
        const groupedDataByCustomer = data.reduce(
            (acc: GroupedDiffCarrierWmsCharges[], item) => {
                const existingGroup = acc.find(
                    (group) => group.customerName === item.customer_name,
                );
                if (existingGroup) {
                    existingGroup.entries.push(item);
                } else {
                    acc.push({
                        customerName: item.customer_name,
                        entries: [item],
                        count: 1,
                        totalDifference: calculateDifference(item),
                    });
                }
                return acc;
            },
            [],
        );
        groupedDataByCustomer.forEach((group) => {
            const entries = group.entries
                .sort((a, b) => calculateDifference(b) - calculateDifference(a))
                .slice(0, 100);

            group.entries = entries;
            group.count = entries.length;
            group.totalDifference = entries.reduce((acc, item) => {
                return acc + calculateDifference(item);
            }, 0);
        });
        setDiffCarrierWmsCharges(groupedDataByCustomer);

        const groupedDataByCarrier = data.reduce(
            (acc: GroupedDiffCarrierWmsChargesByCarrier[], item) => {
                const existingGroup = acc.find(
                    (group) =>
                        group.carrierName ===
                        (item.carrier_name ?? 'Not Found'),
                );
                if (existingGroup) {
                    existingGroup.entries.push(item);
                } else {
                    acc.push({
                        carrierName: item.carrier_name ?? 'Not Found',
                        entries: [item],
                        count: 1,
                        totalDifference:
                            parseFloat(item.carrier_total ?? '0') -
                            parseFloat(item.label_total),
                    });
                }
                return acc;
            },
            [],
        );
        groupedDataByCarrier.forEach((group) => {
            const entries = group.entries
                .sort((a, b) => calculateDifference(b) - calculateDifference(a))
                .slice(0, 100);

            group.entries = entries;
            group.count = entries.length;
            group.totalDifference = entries.reduce((acc, item) => {
                return acc + calculateDifference(item);
            }, 0);
        });
        setDiffCarrierWmsChargesByCarrier(groupedDataByCarrier);
    };

    const fetchMissingWmsData = async () => {
        const response = await missingWmsDataReconciliationMissingWmsDataGet({
            query: {},
        });
        const data = response.data!;
        const groupedData = data.reduce(
            (acc: GroupedMissingWmsData[], item) => {
                const existingGroup = acc.find(
                    (group) => group.carrierName === item.carrier_name,
                );
                if (existingGroup) {
                    existingGroup.entries.push(item);
                    existingGroup.count++;
                    existingGroup.totalCharges += parseFloat(
                        item.carrier_total,
                    );
                } else {
                    acc.push({
                        carrierName: item.carrier_name,
                        entries: [item],
                        count: 1,
                        totalCharges: parseFloat(item.carrier_total),
                    });
                }
                return acc;
            },
            [],
        );
        groupedData.forEach((group) => {
            const entries = group.entries
                .sort(
                    (a, b) =>
                        new Date(b.latest_invoice_date).getTime() -
                        new Date(a.latest_invoice_date).getTime(),
                )
                .slice(0, 100);

            group.entries = entries;
            group.count = entries.length;
            group.totalCharges = entries.reduce(
                (acc, item) => acc + parseFloat(item.carrier_total),
                0,
            );
        });
        setMissingWmsData(groupedData);
    };

    useEffect(() => {
        if (initialFetchDone.current) return;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchDiffCarrierWmsCharges(),
                    fetchMissingWmsData(),
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
        initialFetchDone.current = true;
    }, [diffCarrierWmsCharges, missingWmsData]);

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await getCarriersCarrierBillsCarriersGet();
                if (response.data) {
                    const carriers = response.data.map((carrier) => ({
                        id: carrier.name,
                        name: carrier.name,
                    }));
                    setAvailableCarriers(carriers);
                }
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };

        fetchCarriers();
    }, []);

    const handleDownload = async () => {
        setIsDownloading(true);
        setError(null);

        let response;
        const carrier =
            selectedDownloadCarrier === 'ALL_CARRIERS'
                ? null
                : selectedDownloadCarrier;

        if (
            selectedReport ===
            CarrierReportExportType.CARRIER_VS_WMS_CHARGE_DIFFERENCES
        ) {
            response =
                await diffCarrierWmsChargesJsonReconciliationDiffCarrierWmsChargesJsonGet(
                    {
                        query: {
                            start_date: downloadStartDate.toISOString(),
                            end_date: downloadEndDate.toISOString(),
                            carrier: carrier,
                        },
                    },
                );
        } else if (
            selectedReport === CarrierReportExportType.MISSING_WMS_DATA
        ) {
            response =
                await missingWmsDataJsonReconciliationMissingWmsDataJsonGet({
                    query: {
                        start_date: downloadStartDate.toISOString(),
                        end_date: downloadEndDate.toISOString(),
                        carrier: carrier,
                    },
                });
        } else if (
            selectedReport === CarrierReportExportType.MISSING_CARRIER_DATA
        ) {
            response =
                await missingCarrierDataJsonReconciliationMissingCarrierDataJsonGet(
                    {
                        query: {
                            start_date: downloadStartDate.toISOString(),
                            end_date: downloadEndDate.toISOString(),
                            carrier: carrier,
                        },
                    },
                );
        } else {
            setError('Invalid report type selected');
            setIsDownloading(false);
            return;
        }
        // Create a Blob from the response
        const blob = new Blob([response.data!], {
            type: 'text/csv;charset=utf-8;',
        });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'carrier_bill_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setSuccessMessage('Download successful.');
            setIsDownloading(false);
        } else {
            setError('Download not supported in this browser.');
        }
    };

    const diffCarrierWmsChargesColumns = [
        {
            accessorKey: 'customerName',
            header: 'Customer',
            cell: ({ row }: { row: any }) => (
                <span className="font-medium">
                    {row.getValue('customerName')}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'totalDifference',
            header: 'Total Difference',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('totalDifference')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
    ];

    const diffCarrierWmsChargesByCarrierColumns = [
        {
            accessorKey: 'carrierName',
            header: 'Carrier',
            cell: ({ row }: { row: any }) => (
                <span className="font-medium">
                    {row.getValue('carrierName')}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'totalDifference',
            header: 'Total Difference',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('totalDifference')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
    ];

    const diffCarrierWmsChargesNestedColumns = [
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            cell: ({ row }: { row: any }) => (
                <span className="font-medium">
                    {row.getValue('tracking_number')}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'order_number',
            header: 'Order Number',
            cell: ({ row }: { row: any }) => row.getValue('order_number'),
            enableSorting: true,
        },
        {
            accessorKey: 'carrier_total',
            header: 'Carrier Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('carrier_total')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'label_total',
            header: 'Label Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('label_total')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'order_datetime',
            header: 'Order Date',
            cell: ({ row }: { row: any }) => {
                const date = new Date(row.getValue('order_datetime'));
                return date.toISOString().split('T')[0];
            },
            enableSorting: true,
        },
    ];

    const missingWmsDataColumns = [
        {
            accessorKey: 'carrierName',
            header: 'Carrier',
            cell: ({ row }: { row: any }) => (
                <span className="font-medium">
                    {row.getValue('carrierName')}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'totalCharges',
            header: 'Total Charges',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('totalCharges')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
    ];

    const missingWmsDataNestedColumns = [
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            cell: ({ row }: { row: any }) => (
                <span className="font-medium">
                    {row.getValue('tracking_number')}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'carrier_total',
            header: 'Carrier Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat
                    value={row.getValue('carrier_total')}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'latest_invoice_date',
            header: 'Latest Invoice Date',
            cell: ({ row }: { row: any }) => {
                const date = new Date(row.getValue('latest_invoice_date'));
                return date.toISOString().split('T')[0];
            },
            enableSorting: true,
        },
    ];

    return (
        <div className="container mx-auto p-8">
            {isLoading ? (
                <div className="flex items-center justify-center min-h-[400px]">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
                </div>
            ) : (
                <>
                    <div className="flex justify-end">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button>
                                    <div className="pr-2">
                                        <ArrowDownTrayIcon className="w-4 h-4" />
                                    </div>
                                    <div>Export</div>
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>Export Reports</DialogTitle>
                                    <DialogDescription></DialogDescription>
                                </DialogHeader>
                                <Tabs defaultValue="download">
                                    <TabsList className="grid w-full grid-cols-2">
                                        <TabsTrigger
                                            value="download"
                                            className="w-full"
                                        >
                                            Download
                                        </TabsTrigger>
                                        <TabsTrigger
                                            value="email"
                                            className="w-full"
                                        >
                                            Schedule Email
                                        </TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="download">
                                        <form className="space-y-4">
                                            <div className="space-y-2">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Report Type
                                                </label>
                                                <Select
                                                    value={selectedReport}
                                                    onValueChange={
                                                        setSelectedReport
                                                    }
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {reportOptions.map(
                                                            (option) => (
                                                                <SelectItem
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                >
                                                                    {
                                                                        option.label
                                                                    }
                                                                </SelectItem>
                                                            ),
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                            <div className="space-y-2">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Carrier
                                                </label>
                                                <Select
                                                    value={
                                                        selectedDownloadCarrier
                                                    }
                                                    onValueChange={
                                                        setSelectedDownloadCarrier
                                                    }
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="ALL_CARRIERS">
                                                            All Carriers
                                                        </SelectItem>
                                                        {availableCarriers.map(
                                                            (carrier) => (
                                                                <SelectItem
                                                                    key={
                                                                        carrier.id
                                                                    }
                                                                    value={
                                                                        carrier.id
                                                                    }
                                                                >
                                                                    {
                                                                        carrier.name
                                                                    }
                                                                </SelectItem>
                                                            ),
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                            <div className="flex space-x-4">
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Start Date
                                                    </label>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button variant="outline">
                                                                {format(
                                                                    downloadStartDate,
                                                                    'PP',
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar
                                                                mode="single"
                                                                selected={
                                                                    downloadStartDate
                                                                }
                                                                onSelect={(
                                                                    date,
                                                                ) =>
                                                                    date &&
                                                                    setDownloadStartDate(
                                                                        date,
                                                                    )
                                                                }
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        End Date
                                                    </label>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button variant="outline">
                                                                {format(
                                                                    downloadEndDate,
                                                                    'PP',
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar
                                                                mode="single"
                                                                selected={
                                                                    downloadEndDate
                                                                }
                                                                onSelect={(
                                                                    date,
                                                                ) =>
                                                                    date &&
                                                                    setDownloadEndDate(
                                                                        date,
                                                                    )
                                                                }
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                            </div>
                                            <Button
                                                onClick={handleDownload}
                                                disabled={isDownloading}
                                            >
                                                {isDownloading
                                                    ? 'Downloading...'
                                                    : 'Download'}
                                            </Button>
                                            {successMessage && (
                                                <p className="text-green-500">
                                                    {successMessage}
                                                </p>
                                            )}
                                            {error && (
                                                <p className="text-red-500">
                                                    {error}
                                                </p>
                                            )}
                                        </form>
                                    </TabsContent>
                                    <TabsContent value="email">
                                        <form
                                            onSubmit={handleEmailSubmit}
                                            className="space-y-4"
                                        >
                                            <div className="space-y-2">
                                                <label
                                                    htmlFor="emailRecipients"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email Recipients
                                                </label>
                                                <Input
                                                    id="emailRecipients"
                                                    type="text"
                                                    placeholder="Enter email addresses separated by commas"
                                                    value={emailRecipients}
                                                    onChange={(e) =>
                                                        setEmailRecipients(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="space-y-2">
                                                <label
                                                    htmlFor="reportType"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Report Type
                                                </label>
                                                <Select
                                                    value={reportOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            emailReportType,
                                                    )}
                                                    onChange={(
                                                        selectedOption,
                                                    ) =>
                                                        setEmailReportType(
                                                            selectedOption?.value ||
                                                                '',
                                                        )
                                                    }
                                                    options={reportOptions}
                                                    placeholder="Select report type"
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                />
                                            </div>
                                            <Button
                                                type="submit"
                                                disabled={isSubmittingEmail}
                                            >
                                                {isSubmittingEmail
                                                    ? 'Scheduling...'
                                                    : 'Schedule Email'}
                                            </Button>
                                            {successMessage && (
                                                <p className="text-green-500">
                                                    {successMessage}
                                                </p>
                                            )}
                                            {error && (
                                                <p className="text-red-500">
                                                    {error}
                                                </p>
                                            )}
                                        </form>
                                    </TabsContent>
                                </Tabs>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <h2 className="text-xl font-semibold">
                        Mismatch Between Carrier Charges and Recorded Label
                        Rates
                    </h2>
                    <h3 className="text-sm text-gray-500 mb-4">
                        This table shows the first 100 shipments with the
                        largest discrepancies for each carrier/customer. Export
                        the full report to see all.
                    </h3>
                    <Tabs defaultValue="by_customer">
                        <TabsList>
                            <TabsTrigger value="by_customer">
                                By Customer
                            </TabsTrigger>
                            <TabsTrigger value="by_carrier">
                                By Carrier
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value="by_customer">
                            {diffCarrierWmsCharges &&
                            diffCarrierWmsCharges.length > 0 ? (
                                <DataTable
                                    loading={isLoading}
                                    data={diffCarrierWmsCharges}
                                    columns={diffCarrierWmsChargesColumns}
                                    isMultiSelectRows={false}
                                    collapsible
                                    showActionButtonInToolbar={false}
                                    showActionButtonInCollapsible={false}
                                    showToolBar
                                    collapsibleContent={({ row }) => {
                                        if (row?.entries) {
                                            return (
                                                <DataTable
                                                    loading={isLoading}
                                                    data={row.entries}
                                                    columns={
                                                        diffCarrierWmsChargesNestedColumns
                                                    }
                                                    isMultiSelectRows={false}
                                                    showPagination={false}
                                                    showToolBar={false}
                                                    showActionButtonInToolbar={
                                                        false
                                                    }
                                                    showActionButtonInCollapsible={
                                                        false
                                                    }
                                                    collapsible={false}
                                                    isDownloadable={true}
                                                    downloadData={row.entries}
                                                    tableName="diff_carrier_wms_charges"
                                                />
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            ) : (
                                <p>
                                    No data available for Carrier Charges and
                                    Recorded Label Rates mismatch.
                                </p>
                            )}
                        </TabsContent>
                        <TabsContent value="by_carrier">
                            {diffCarrierWmsChargesByCarrier &&
                            diffCarrierWmsChargesByCarrier.length > 0 ? (
                                <DataTable
                                    loading={isLoading}
                                    data={diffCarrierWmsChargesByCarrier}
                                    columns={
                                        diffCarrierWmsChargesByCarrierColumns
                                    }
                                    isMultiSelectRows={false}
                                    collapsible
                                    showActionButtonInToolbar={false}
                                    showActionButtonInCollapsible={false}
                                    showToolBar
                                    collapsibleContent={({ row }) => {
                                        if (row?.entries) {
                                            return (
                                                <DataTable
                                                    loading={isLoading}
                                                    data={row.entries}
                                                    columns={
                                                        diffCarrierWmsChargesNestedColumns
                                                    }
                                                    isMultiSelectRows={false}
                                                    showPagination={false}
                                                    showToolBar={false}
                                                    showActionButtonInToolbar={
                                                        false
                                                    }
                                                    showActionButtonInCollapsible={
                                                        false
                                                    }
                                                    collapsible={false}
                                                    isDownloadable={true}
                                                    downloadData={row.entries}
                                                    tableName="diff_carrier_wms_charges_by_carrier"
                                                />
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            ) : (
                                <p>
                                    No data available for Carrier Charges and
                                    Recorded Label Rates mismatch.
                                </p>
                            )}
                        </TabsContent>
                    </Tabs>

                    <h2 className="text-xl font-semibold mt-8">
                        Shipments Billed But Not Recorded in WMS
                    </h2>
                    <h3 className="text-sm text-gray-500 mb-4">
                        This table shows the latest 100 shipments missing from
                        WMS. Export the full report to see all.
                    </h3>
                    {missingWmsData && missingWmsData.length > 0 ? (
                        <DataTable
                            loading={isLoading}
                            data={missingWmsData}
                            columns={missingWmsDataColumns}
                            isMultiSelectRows={false}
                            collapsible
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible={false}
                            showToolBar
                            collapsibleContent={({ row }) => {
                                if (row?.entries) {
                                    return (
                                        <DataTable
                                            loading={isLoading}
                                            data={row.entries}
                                            columns={
                                                missingWmsDataNestedColumns
                                            }
                                            isMultiSelectRows={false}
                                            showPagination={false}
                                            showToolBar={false}
                                            showActionButtonInToolbar={false}
                                            showActionButtonInCollapsible={
                                                false
                                            }
                                            collapsible={false}
                                            downloadData={row.entries}
                                            tableName="missing_wms_data"
                                        />
                                    );
                                }
                                return null;
                            }}
                        />
                    ) : (
                        <p>
                            No data available for Shipments Billed But Not
                            Recorded in WMS.
                        </p>
                    )}
                </>
            )}
        </div>
    );
}
