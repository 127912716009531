import { QueueListIcon } from '@heroicons/react/24/outline';

import Select from 'react-select';
import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';

const PricingCategoryForm = ({ formProps, chargeLevels }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">Category Name</div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter pricing category name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Reported On Client Invoice As</div>
                <FormField
                    control={formProps.control}
                    name="charge_on_invoice"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter charge name on invoice" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-5">
                <div className="flex text-xl font-medium">
                    <QueueListIcon className="h-6 w-6 mr-2" />
                    Charge Application Level
                </div>
                <div className="mt-2">
                    <FormField
                        control={formProps.control}
                        name="charge_level"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={chargeLevels}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </form>
    </Form>
);

export default PricingCategoryForm;
