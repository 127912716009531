import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import PricingRuleForm from './pricingRuleForm';
import { editInvoiceRulesRuleIdEditPut } from '../../../client/services.gen';
import type { RuleConditionCol, RuleCreate } from '../../../client/types.gen';
import LoadingSpinner from '../../common/loadingSpinner';
import type {
    Filter,
} from '@/client/types.gen';
interface PricingRuleEditProps {
    ruleId: string;
    pricingRule: RuleCreate;
    ruleConditionMetadata: RuleConditionCol[];
    setRuleId: (id: string) => void;
    setRuleChange: (changed: boolean) => void;
    ruleChange: boolean;
    setPricingRuleDialogOpen: (open: boolean) => void;
}

const transformValues = (values: any): RuleCreate => {
    return {
        name: values.name,
        charge_type_id: values.charge_type.value,
        default: values.default,
        customer_ids: values.customer_ids?.map((c: any) => c.value) || [],
        rule_conditions:
            values.rule_conditions?.map((condition: any) => ({
                col: condition.col.value,
                filter: condition.filter.value,
                value: Array.isArray(condition.value)
                    ? condition.value.map((v: any) => v.value).join(',')
                    : typeof condition.value === 'object'
                      ? condition.value.value
                      : condition.value,
            })) || [],
        tiers: values.tiers.map((tier: any) => ({
            pricing_choices: tier.pricing_choices.map((choice: any) => ({
                method: choice.method,
                value: choice.value,
                col: choice.col,
            })),
            pricing_choices_filter: tier.pricing_choices_filter,
            tier_conditions: tier.tier_conditions?.rangeCol
                ? [
                      ...(tier.tier_conditions.rangeStart
                          ? [
                                {
                                    col: tier.tier_conditions.rangeCol,
                                    filter: '>=' as Filter,
                                    value: String(tier.tier_conditions.rangeStart),
                                },
                            ]
                          : []),
                      ...(tier.tier_conditions.rangeEnd
                          ? [
                                {
                                    col: tier.tier_conditions.rangeCol,
                                    filter: '<=' as Filter,
                                    value: String(tier.tier_conditions.rangeEnd),
                                },
                            ]
                          : []),
                  ]
                : undefined,
        })),
        tiers_are_cumulative: values.tiers_are_cumulative,
    };
};

const PricingRuleEdit = ({
    ruleId,
    pricingRule,
    ruleConditionMetadata,
    setRuleId,
    setRuleChange,
    ruleChange,
    setPricingRuleDialogOpen,
}: PricingRuleEditProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingRuleConditionsSchema = z.object({
        col: dropDownSchema,
        filter: dropDownSchema,
        value: z
            .string()
            .min(1, { message: 'Required' })
            .optional()
            .or(dropDownSchema.optional())
            .or(z.array(dropDownSchema)),
    });

    const pricingTierRangeConditionSchema = z.object({
        rangeCol: z.string().nullable().optional(),
        rangeStart: z.coerce.string().nullable().optional(),
        rangeEnd: z.coerce.string().nullable().optional(),
    });

    const pricingChoice = z.object({
        method: z.string().trim().min(1, { message: 'Method is required.' }),
        value: z.string().min(1, { message: 'Value is required.' }),
        col: z.string().nullable(),
    });

    const pricingTierSchema = z
        .object({
            tier_conditions: pricingTierRangeConditionSchema.optional(),
            pricing_choices: z.array(pricingChoice),
            pricing_choices_filter: z.string().nullable().optional(),
        })
        .refine(
            (input) => {
                const hasSpecialMethod = input.pricing_choices.some(
                    (choice) =>
                        choice.method === 'variable_cost' ||
                        choice.method === 'cumulative',
                );
                if (hasSpecialMethod) {
                    if (!input.tier_conditions) {
                        return false;
                    }
                    const { rangeCol, rangeStart } = input.tier_conditions;
                    if (!rangeCol || !rangeStart) {
                        return false;
                    }
                }
                return true;
            },
            {
                message:
                    'Defining tier conditions is required when the method is "variable_cost" or "cumulative". Please ensure both rangeCol and rangeStart are provided.',
                path: ['tier_conditions'],
            },
        )
        .refine(
            (input) => {
                // Require pricing_choices_filter if there is more than one pricing_choice
                if (
                    input.pricing_choices.length > 1 &&
                    !input.pricing_choices_filter
                ) {
                    return false;
                }
                return true;
            },
            {
                message:
                    'Please select a selection method if more than one pricing type is entered',
                path: ['tier_conditions'],
            },
        );

    const pricingRuleSchema = z
        .object({
            name: z.string().trim().min(3, { message: 'Required' }),
            default: z.boolean(),
            rule_conditions: z.array(pricingRuleConditionsSchema).optional(),
            charge_type: dropDownSchema,
            tiers: z.array(pricingTierSchema),
            customer_ids: z.array(dropDownSchema).optional(),
            tiers_are_cumulative: z.boolean(),
        })
        .refine(
            (input) => {
                if (!input.default && input.customer_ids?.length === 0) {
                    return false;
                }

                return true;
            },
            {
                message:
                    'Customer selection is required if this rule is not a default rule.',
                path: ['customer_ids'],
            },
        );

    // Transform the pricingRule to include tier ranges in defaultValues
    const defaultValues = {
        name: pricingRule?.name || '',
        default: pricingRule?.default || false,
        charge_type: pricingRule?.charge_type || null,
        customer_ids: pricingRule?.customer_ids || [],
        tiers_are_cumulative: pricingRule?.tiers_are_cumulative || false,
        rule_conditions: pricingRule?.rule_conditions || [],
        tiers:
            pricingRule?.tiers?.map((tier) => ({
                ...tier,
                pricing_choices:
                    tier.pricing_choices?.map((choice) => ({
                        method: choice.method || '',
                        value: choice.value || '',
                        col: choice.col || null,
                    })) || [],
                pricing_choices_filter: tier.pricing_choices_filter || null,
                tier_conditions: {
                    rangeCol: tier.tier_conditions?.rangeCol || '',
                    rangeStart: tier.tier_conditions?.rangeStart || '',
                    rangeEnd: tier.tier_conditions?.rangeEnd || '',
                },
            })) || [],
    };

    const formProps = useForm({
        resolver: zodResolver(pricingRuleSchema),
        defaultValues,
    });

    const onSubmit = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await editInvoiceRulesRuleIdEditPut({
                path: { ruleId },
                body: transformValues(values),
            });
            if (response.error) {
                console.error('Error updating pricing rule:', response.error);
                return;
            } else {
                setRuleId(ruleId);
                setIsDialogOpen(false);
                setPricingRuleDialogOpen(false);
                setRuleChange(!ruleChange);
            }
        } catch (err) {
            console.error('Error updating pricing rule:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button className="ml-2" onClick={() => setIsDialogOpen(true)}>
                Edit
            </Button>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="max-w-4xl h-[90vh] flex flex-col">
                    <DialogTitle className="text-3xl">
                        Edit Pricing Rule
                    </DialogTitle>
                    <div className="text-rails-dark-blue flex-1 overflow-y-auto">
                        <form onSubmit={formProps.handleSubmit(onSubmit)}>
                            <PricingRuleForm
                                formProps={formProps}
                                ruleConditions={ruleConditionMetadata}
                                pricingRule={pricingRule}
                                onSubmit={onSubmit}
                                isLoading={isLoading}
                            />
                            <div className="fixed bottom-0 right-0 p-6 bg-white border-t border-gray-200 w-full max-w-4xl">
                                <div className="flex justify-end">
                                    <Button disabled={isLoading} type="submit">
                                        {isLoading ? (
                                            <>
                                                <LoadingSpinner
                                                    className="h-4 w-4 mr-2"
                                                    loading={isLoading}
                                                />
                                                Saving
                                            </>
                                        ) : (
                                            <>Save</>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PricingRuleEdit;
