import { type Table } from '@tanstack/react-table';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DataTableViewOptions } from './data-table-viewoptions.tsx';
import { DataTableFacetedFilter } from './data-table-facetedfilter.tsx';
import React from 'react';
import { DataTableNumberFilter } from './filters/data-table-number-filter.tsx';
import { DataTableDateFilter } from './filters/data-table-date-filter.tsx';
import { DataTableBooleanFilter } from './filters/data-table-boolean-filter.tsx';

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    toolbarColumnFilters?: any;
    globalFilter: string;
    ActionButton?: React.ReactNode;
}

function getUniqueValues(table: Table<any>, columnId: string) {
    const uniqueValues = new Set<string>();
    
    table.getCoreRowModel().rows.forEach((row) => {
        const value = row.getValue(columnId);
        if (typeof value === 'string') {
            uniqueValues.add(value);
        } else if (value !== null && value !== undefined) {
            uniqueValues.add(String(value));
        }
    });

    return Array.from(uniqueValues)
        .sort()
        .map((value) => ({
            label: value,
            value: value,
        }));
}

export function DataTableToolbar<TData>({
    table,
    globalFilter,
    ActionButton,
}: DataTableToolbarProps<TData>) {
    const isFiltered = table.getPreFilteredRowModel().rows.length > table.getFilteredRowModel().rows.length;

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder="Search all columns..."
                    value={globalFilter ?? ''}
                    onChange={(event) => table.setGlobalFilter(event.target.value)}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                {table.getAllColumns().map((column) => {
                    if (!column.getCanFilter()) return null;

                    const filterType = (column.columnDef as any).filterType;

                    switch (filterType) {
                        case 'text':
                            return (
                                <DataTableFacetedFilter
                                    key={column.id}
                                    column={column}
                                    title={column.columnDef.header as string}
                                    options={getUniqueValues(table, column.id)}
                                />
                            );
                        case 'boolean':
                            return (
                                <DataTableBooleanFilter
                                    key={column.id}
                                    column={column}
                                    title={column.columnDef.header as string}
                                />
                            );
                        case 'number':
                            return (
                                <DataTableNumberFilter
                                    key={column.id}
                                    column={column}
                                    title={column.columnDef.header as string}
                                />
                            );
                        case 'date':
                            return (
                                <DataTableDateFilter
                                    key={column.id}
                                    column={column}
                                    title={column.columnDef.header as string}
                                />
                            );
                        default:
                            return null;
                    }
                })}
                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={() => {
                            table.resetColumnFilters();
                            table.resetGlobalFilter();
                        }}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <X className="ml-2 h-4 w-4" />
                    </Button>
                )}
            </div>
            <div className="flex items-center space-x-2">
                <DataTableViewOptions table={table} />
                {ActionButton && <>{ActionButton}</>}
            </div>
        </div>
    );
}
