import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './auth/AuthProvider';
import LoadingSpinner from './common/loadingSpinner';

interface ProtectedRouteProps {
    component: React.ComponentType;
}

const ProtectedRoute = ({ component: Component }: ProtectedRouteProps) => {
    const { loadingAuth, token } = useAuth();
    const location = useLocation();

    return (
        <>
            <LoadingSpinner loading={loadingAuth} />
            {!loadingAuth && token && <Component />}
            {!loadingAuth && !token && (
                <Navigate to={`/login?redirect=${location.pathname}`} />
            )}
        </>
    );
};

export default ProtectedRoute;
